<template>
  <div class="flex flex-row w-full">
    <custom-card
      title="Αποστολή αρχείων παραγωγής"
      class="mt-2"
      extraClass="w-full"
    >
      <div>
        <div class="flex flex-row mt-8 items-center justify-center">
          <div class="flex flex-col mx-2 w-60">
            <label
              class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2"
              for="startDateInput"
              >ΑΠΟ ΗΜΕΡΟΜΗΝΙΑ ΕΚΔΟΣΗΣ</label
            >
            <k-date-picker
              id="startDateInput"
              :format="'dd/MM/yyyy'"
              :value="startDateFilter"
              :max="maxStartDateFilterValue"
              @change="startDateChanged"
            >
              <span class="close-btn">XXXX</span>
            </k-date-picker>
            <span v-if="nullDay2==true" class="text-rose-600 text-xs text-left italic">
              Παρακαλω ελέγξτε την ημερομηνία
            </span>
          </div>
          <div>
         
           
          </div>

          <div class="flex flex-col mx-2 w-60">
            <label
              class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2"
              for="endDateInput"
              >ΕΩΣ ΗΜΕΡΟΜΗΝΙΑ ΕΚΔΟΣΗΣ</label
            >
            <k-date-picker
              id="endDateInput"
              :format="'dd/MM/yyyy'"
              :value="endDateFilter"
              :min="minEndDateFilterValue"
              @change="endDateChanged"
            >
            </k-date-picker>
            <span v-if="nullDay2==true" class="text-rose-600 text-xs text-left italic">
              Παρακαλω ελέγξτε την ημερομηνία
            </span>
          </div>
          
        </div>

        <k-button
          class="my-8 mx-2 px-2 w-40 text-white transition-colors duration-300 bg-red-500 rounded-md shadow enabled:hover:bg-red-600 focus:outline-none"
          @click="sendMessage"
          >ΑΠΟΣΤΟΛΗ</k-button
        >
      </div>
    </custom-card>
  </div>
</template>

<style>
.kgrid-td-rightalign {
  text-align: right !important;
}

.k-grid td {
  white-space: nowrap;
}
</style>

<style scoped>
.k-grid td {
  white-space: nowrap;
}

.expand {
  animation: slideDown 0.2s linear;
  overflow: hidden;
}

.expand-leave-active.expand-leave-to {
  transition: height 0.2s linear;
  height: 0;
}

@keyframes slideDown {
  from {
    height: 0;
  }
  to {
    height: 20px;
  }
}

.appear {
  animation: ease-in-out 0.2s linear;
}

.appear-leave-active.appear-leave-to {
  transition: ease-in-out 0.2s linear;
}
</style>

<script>
import { Grid, GridToolbar } from "@progress/kendo-vue-grid";
import { Button } from "@progress/kendo-vue-buttons";
import { process } from "@progress/kendo-data-query";
import { saveExcel } from "@progress/kendo-vue-excel-export";
import { Dialog, DialogActionsBar } from "@progress/kendo-vue-dialogs";
import ColumnMenu from "@/components/ColumnMenu.vue";

import { DropDownList, MultiSelect } from "@progress/kendo-vue-dropdowns";
import { DatePicker } from "@progress/kendo-vue-dateinputs";
import { Input } from "@progress/kendo-vue-inputs";
import { Checkbox } from "@progress/kendo-vue-inputs";
import CustomCardGrid from "@/components/CustomCardGrid.vue";
import { toRaw } from "vue";
import CustomCard from "@/components/CustomCard.vue";
import SpinnerButton from "@/components/SpinnerButton.vue";

import "@progress/kendo-theme-default/dist/all.css";

import {
  IntlProvider,
  load,
  LocalizationProvider,
  loadMessages,
  IntlService,
} from "@progress/kendo-vue-intl";

const dataItemKey = "id";
const checkField = "checkField";
const checkIndeterminateField = "checkIndeterminateField";
const subItemsField = "items";
const expandField = "expanded";
const textField = "text";

const fields = {
  dataItemKey,
  checkField,
  checkIndeterminateField,
  expandField,
  subItemsField,
};
const allData = [
  { id: 1, text: "Small" },
  { id: 2, text: "Medium" },
  { id: 3, text: "Large" },
];
// function formatDates(items, noFormat) {
//   items.forEach((o) => {
//     // o.cntrctStrD = intl.parseDate(
//     //   o.cntrctStrD,
//     //   noFormat ? DATE_FORMAT : DATE_FORMAT
//     //);
//     console.log(o.effectDate);

//     var paymentDate = new Date(o.pmntDate);
//     var effectDate = new Date(o.effectDate);
//     var cntDueDate = new Date(o.cntDueDate);

//     //console.log(Intl.DateTimeFormat('en-GB').format(d)); // Hours
//     o.pmntDate = paymentDate;
//     o.effectDate = effectDate;
//     o.cntDueDate = cntDueDate;
//     // console.log(d.getUTCDay());
//     // console.log(d.getUTCFullYear());

//     // o.cntrctStrD =
//     //   o.cntrctStrD === "NULL"
//     //     ? undefined
//     //     : intl.parseDate(o.cntrctStrD, "dd/MM/yyyy" ? DATE_FORMAT : undefined);
//     //console.log(Intl.DateTimeFormat('en-GB').format(o.cntrctStrD));
//   });
//   return items;
// }

export default {
  name: "Intergations",
  inject: ["$appConfig"],
  components: {
    "k-grid": Grid,
    "k-grid-toolbar": GridToolbar,
    "k-button": Button,
    "k-dialog": Dialog,
    "k-dialog-actions-bar": DialogActionsBar,
    "k-drop-down-list": DropDownList,
    "k-date-picker": DatePicker,
    "k-multi-select": MultiSelect,
    "k-input": Input,
    "k-checkbox": Checkbox,
    CustomCard,
    SpinnerButton,
    CustomCardGrid,
    ColumnMenu,
  },
  data() {
    return {
      isVisible: false,
      visible: false,
      sortable: true,
      filterable: true,
      groupable: true,
      reorderable: true,
      resizable: true,
      showFilters: true,

      filterValues: null,
      threelettersAgentCode: null,
      startDateFilter: null,
      endDateFilter: null,

      insuranceCompanyFilter: null,
      insuranceBranchesFilter: null,

      contractTypeFilter: null,

      commissionAgentCodeFilter: null,
      allCommissionAgentCodesFilter: null,

      serviceAgentCodeFilter: null,
      allServiceAgentCodesFilter: null,

      licTradNumFilter: null,
      docNoFilter: null,
      contractStatusFilter: null,
      gridData: [],
      gridDataParsed: [],
      //
      selectedField: "selected",

      gridSort: null,
      gridFilter: null,

      gridSkip: null,
      gridTake: null,
      vatNum: null,
      gridVat: [],
      nullDay1:false,
      nullDay2:false,
      insertParams:{}
    };
  },

  mounted() {
    // this.loadData()
    // this.loadFilterValues();
  },

  methods: {
    sendMessage() {
   
      const url = this.intergrationUrl.href;
      let params = {};
      // params = { startDate: this.startDateFilter, endDate: this.endDateFilter };
      // console.log(params);
      if(this.startDateFilter==null){
      
        this.nullDay1=true;
        // return false;
      }
      if(this.endDateFilter==null){
        this.nullDay2=true;
        // return false;
      }
      if((this.startDateFilter==null && this.endDateFilter==null)||this.startDateFilter==null||this.endDateFilter==null){
        return false;
      }
      this.axios
        .get(url, {
          headers: { Authorization: "Bearer " + this.$store.state.token },
          params: params,
        })
        .then((response) => {
          // this.insertParams = params;
          if(response.data=="Υπάρχει ενεργή αποστολή γεφυρών θέλετε να ακυρωθεί;"){
       
            this.$swal
            .fire({
              title: "Υπάρχει ενεργή αποστολή γεφυρών θέλετε να ακυρωθεί;",
              showDenyButton: true,
              confirmButtonText: `Ναι`,
              denyButtonText: `Όχι`,
              icon: "question",
            })
            .then((result) => {
              if (result.isConfirmed) {
                this.abortIntergation();
                
                this.insertIntergation();
              
                // if( this.abortIntergation){
                //   this.insertIntergation();
                // }
                
                // this.insertIntergation();
                this.startDateFilter=null;
                this.endDateFilter=null;
              } else {
                this.startDateFilter=null;
                this.endDateFilter=null;
                return false;
              }
            });
           
          }else{
           
              this.insertIntergation();
              this.$swal.fire({
                title: "Επιτυχία",
                text: `Το αίτημα αποστολής των αρχείων μέσω email καταχωρήθηκε με επιτυχία!`,
                icon: "success",
              });
              this.startDateFilter=null;
              this.endDateFilter=null;
          }
         
        })
        .catch((error) => {
          // console.log(error)
        });
    },
    // getIntergation() {
    //   console.log("i am in abort");
    //   let params = {};
    //   params = { username: this.$store.state.user};
    //   const abortUrl = this.abortIntergrationUrl.href;
    //   this.axios.post(abortUrl, params).then((response)=>{
    //                 console.log("ok done i wrote"); 
    //             })
    // },
    abortIntergation() {
      let params = {};
      params = { username: this.$store.state.user};
      const abortUrl = this.abortIntergrationUrl.href;
      this.axios.post(abortUrl, params).then((response)=>{
                    // console.log("ok done i wrote"); 
                })
    },
    insertIntergation() {
      
       
      const inserturl = this.insertIntergrationUrl.href;
   
      let params = {};
      params = {username: this.$store.state.user, startDate: this.startDateFilter, endDate: this.endDateFilter };
      // console.log(params);
      this.axios.post(inserturl, params).then((response)=>{
                this.$swal.fire({
                            title: "Επιτυχία",
                            text: `Το αίτημα αποστολής των αρχείων μέσω email καταχωρήθηκε με επιτυχία!`,
                            icon: "success",
                          });
                })
    },
    filterChange(event) {
      this.items = this.filterData(event.filter);
    },
    filterData(filter) {
      const data = allData.slice();
      return filterBy(data, filter);
    },
    onExpandChange(event) {
      this.expanded = expandedState(event.item, dataItemKey, this.expanded);
    },
    pageChangeHandler: function (event) {
      this.loader = false;
      this.gridSkip = event.page.gridSkip;
      this.gridTake = event.page.gridTake;
    },
    isColumnLocked(columnName) {
      return this.columns.filter((ev) => ev.field === columnName)[0].locked;
    },
    lockChange(state, columnName) {
      const columnToLock = this.columns.filter(
        (ev) => ev.field === columnName.field
      )[0];
      columnToLock.locked = state;
    },
    visibilityChange(state, columnName) {
      this.columns.filter((ev) => ev.field === columnName.field)[0].hidden =
        state;
    },
    onColumnsSubmit(columnsState) {
      this.columns = columnsState;
    },
    createAppState: function (dataState) {
      this.take = dataState.take;
      this.skip = dataState.skip;
      if (dataState.group) {
        dataState.group.map((group) => (group.aggregates = this.aggregates));
      }
      this.group = dataState.group;
      this.filter = dataState.filter;
      this.sort = dataState.sort;
    },
    dataStateChange(event) {
      this.createAppState(event.data);
      this.dataResult = process(orders, {
        skip: this.skip,
        take: this.take,
        sort: this.sort,
        filter: this.filter,
        group: this.group,
      });
    },
    columnReorder: function (options) {
      this.columns = options.columns;
    },
    columnResize: function (options) {
      this.columns = options.columns;
    },
    onRowClick(event) {
      if (event.dataItem)
        event.dataItem[this.selectedField] =
          !event.dataItem[this.selectedField];
    },
    clearFilters() {
      this.startDateFilter = null;
      this.endDateFilter = null;
      this.insuranceCompanyFilter = null;
      this.insuranceBranchesFilter = null;

      this.licTradNumFilter = null;
      this.commissionAgentCodeFilter = null;
      this.docNoFilter = null;

      this.serviceAgentCodeFilter = null;
      this.contractStatusFilter = null;

      this.gridFilter = null;
      this.gridData = [];
      this.gridDataParsed = [];
    },

    newReport() {
      //clear filters, maybe?

      //and clear data
      this.gridData = null;
    },

    

    loadFilterValues() {
      const url = this.reportFilterValuesUrl.href;

      this.axios
        .get(url, {
          headers: { Authorization: "Bearer " + this.$store.state.token },
        })
        .then((response) => (this.filterValues = response.data));
    },

    gridSortChangeHandler(event) {
      this.gridSort = event.sort;
    },
    gridFilterChangeHandler(event) {
      this.gridFilter = event.filter;
      // console.log(this.gridFilter)
    },
    gridPageChangeHandler(event) {
      this.gridTake = event.page.take;
      this.gridSkip = event.page.skip;
    },

    exportExcel() {
      const nowString = new Date().toISOString();
      const date = nowString.split("T")[0];
      const time = nowString.split("T")[1].split(".")[0];

      const d = date.replace("-", "").replace("-", "");
      const t = time.replace(":", "").replace(":", "");

      const ts = `${d}${t}`;

      saveExcel({
        columns: this.gridColumns.map((item) => {
          return { ...item, hidden: false };
        }),
        data: this.gridData,
        fileName: `kua-nn-portal-paid-report-${ts}`,
      });
    },

    startDateChanged(event) {
      this.nullDay1=false;
      this.startDateFilter = event.value;

    },

    endDateChanged(event) {
      this.nullDay2=false;
      this.endDateFilter = event.value;
    },
  },

  computed: {
    minEndDateFilterValue() {
      if (this.startDateFilter) return this.startDateFilter;
    },

    maxStartDateFilterValue() {
      if (this.endDateFilter) return this.endDateFilter;
    },

    showAllCommissionAgentCodesCheckbox() {
      return this.commissionAgentCodeFilter?.length > 0;
    },

    showAllServiceAgentCodesCheckbox() {
      return this.serviceAgentCodeFilter?.length > 0;
    },

    gridTotal() {
      return this.gridData?.length ?? 0;
    },

    reportUrl() {
      return new URL(
        this.$appConfig.API_PAID_REPORT_URL,
        this.$appConfig.API_BASE_URL
      );
    },
    intergrationUrl() {
      return new URL(
        this.$appConfig.API_INTERGRATIONS_URL,
        this.$appConfig.API_BASE_URL
      );
    },
    insertIntergrationUrl() {
      return new URL(
        this.$appConfig.API_INSERT_INTERGRATIONS_URL,
        this.$appConfig.API_BASE_URL
      );
    },
    abortIntergrationUrl() {
      return new URL(
        this.$appConfig.API_ABORT_INTERGRATIONS_URL,
        this.$appConfig.API_BASE_URL
      );
    },
    vatDataUrl() {
      return new URL(
        this.$appConfig.API_CUSTOMERS_DATA,
        this.$appConfig.API_BASE_URL
      );
    },

    reportFilterValuesUrl() {
      return new URL(
        this.$appConfig.API_REPORTS_DROPDOWN_FILTER_VALUES,
        this.$appConfig.API_BASE_URL
      );
    },

    processedGridData() {
      return process(this.gridData, {
        sort: this.gridSort,
        filter: this.gridFilter,
        take: this.gridTake,
        skip: this.gridSkip,
      });
    },
  },
  processedVatGridData() {
    return process(this.gridVat, {
      sort: this.gridSort,
      filter: this.gridFilter,
      take: this.gridTake,
      skip: this.gridSkip,
    });
  },
  created() {
    this.threelettersAgentCode = this.filterValues?.allAgentCodes.slice();
    // console.log(this.filterValues?.allAgentCodes);
  },
};
</script>
