<template>

  <div class="flex flex-col" v-if="formShow==true && gettingQuestions==false && docShow==false">
    <div class="flex flex-row p-4">
      <div class="flex-col w-3/4" >
        <custom-card title="Στοιχεία ταξιδιωτών">
          <div
            class="content-center justify-center text-center items-center pt-4"
          >
            <table class="justify-center table table-auto mt-4">
              <thead>
                <tr>
                  <th>Τίτλος</th>
                  <th>Όνομα</th>
                  <th>Επώνυμο</th>
                  <th>Ηλικιακή ομάδα</th>
                  <th>Ημ/νία γέννησης</th>
                  <th>Ηλικία</th>
                </tr>
              </thead>
              <tbody>
                <template
                  v-for="traveller in travellers"
                  v-bind:key="traveller"
                >
                  <tr>
                    <td class="w-40">
                      <k-drop-down-list
                        :data-items="titles"
                        :default-item="'Παρακαλώ επιλέξτε'"
                        v-model="traveller.title"
                      />
                    </td>
                    <td class="w-60">
                      <k-input v-model="traveller.firstName" class="w-60" />
                    </td>
                    <td class="w-60">
                      <k-input v-model="traveller.lastName" class="w-60" />
                    </td>
                    <td class="w-30">
                      {{ traveller.ageBand.minAge }} -
                      {{ traveller.ageBand.maxAge }}
                    </td>

                    <!-- //v1.0.3.8	NewPolicyRequestForm.vue : Αλλαγή στην εμφάνιση της ημερομηνίας -->
                    <td class="w-60">
                      <k-date-picker
                        class="w-40"
                        :format="'dd/MM/yyyy'"
                        :min="minimumDateOfBirth(traveller)"
                        :max="maximumDateOfBirth(traveller)"
                        v-model="traveller.dateOfBirth"
                      />
                    </td>
                    <!-- //v1.0.3.8	NewPolicyRequestForm.vue : Αλλαγή στην εμφάνιση της ημερομηνίας -->

                    <td class="w-20">
                      {{ getAge(traveller.dateOfBirth) }}
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </custom-card>

        <!------- //v1.0.3.7	Aλλαγές στη μορφοποίηση ------->
        <custom-card title="Στοιχεία πελάτη">
          <div
            class="flex flex-col justify-center content-center text-center container pt-4 w-full"
          >
            <div class="flex flex-row mt-2">
              <div class="mx-2">
                <k-drop-down-list
                  :data-items="legalForms"
                  class="w-60"
                  :default-item="'Παρακαλώ επιλέξτε'"
                  label="Νομική μορφή"
                  v-model="customer.legalForm"
                />
              </div>

              <div v-if="customer.legalForm == 'Individual'">
                <k-drop-down-list
                  :data-items="travellersFullNames"
                  :default-item="'Παρακαλώ επιλέξτε'"
                  class="w-80"
                  label="Επωνυμία πελάτη"
                  v-model="customer.name"
                />
              </div>

              <div v-else-if="customer.legalForm == 'Company'">
                <k-input
                  v-model="customer.name"
                  class="w-60 mx-2"
                  label="Επωνυμία εταιρίας"
                />
              </div>
                <k-input
                  v-model="customer.vatNumber"
                  class="w-40 mx-2"
                  label="ΑΦΜ"
                />
            </div>

            <div class="flex flex-row mt-2">
              <div class="mx-2">
                <k-input v-model="customer.email" class="w-60" label="E-Mail" />
              </div>

              <div class="mx-2">
                <k-input
                  @keydown="checkDigit"
                  v-model="customer.telephone"
                  class="w-60"
                  label="Αριθμός κιν. τηλεφώνου"
                />
              </div>

              <div class="mx-2">
                <k-input
                  v-model="customer.address"
                  class="w-60"
                  label="Διεύθυνση"
                />
              </div>

              <div class="mx-2">
                <k-input
                  v-model="customer.postalCode"
                  class="w-60"
                  label="Ταχ. Κώδικας"
                />
              </div>
            </div>
          </div>
        </custom-card>
        <!-- <custom-card title="Στοιχεία Συνεργάτη">
          <div
            class="flex flex-col justify-center content-center text-center container pt-4 w-full"
          >
            <div class="flex flex-row mt-2">
              <div class="mx-2">
                <k-form-field
                  class="w-60"
                  :id="'sobo'"
                  :name="'sobo'"
                  :default-item="'Παρακαλώ επιλέξτε'"
                  label="Συνεργάτης (Sell on Behalf of)"
                  :data-items="sobo ?? []"
                  :text-field="'label'"
                  :data-item-key="'value'"
                  :layout="'horizontal'"
                  :component="'myTemplate'"
                  :validator="requiredValidator"
                >
                  <template v-slot:myTemplate="{ props }">
                    <k-drop-down-list
                      :style="{ width: '450px' }"
                      v-bind="props"
                      @change="isNNAgent"
                    />
                  </template>
                </k-form-field>
              </div>
              v-on:keyup.enter="verify"
                  v-on:blur="verify"
              <div v-if="isNN == true" class="flex flex-row mt-0 shrink-0">
                <k-drop-down-list
                  :id="'NNAgentsobo'"
                  :name="'NNAgentsobo'"
                  v-model="agentCode"
                  :data-items="NNAgntsobo ?? []"
                  :default-item="'Παρακαλώ επιλέξτε Κωδικό Συνεργάτη ΝΝ'"
                  class="w-80"
                  label="Κωδικός Συνεργάτη ΝΝ"
                  @change="verify"
                />
                <div>
                  <svg
                    v-if="verifiedAgent && !verifiedAgent.isDisabled"
                    class="inline mr-2 w-4 h-4 text-gray-200 dark:text-gray-600 stroke-green-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <polyline points="20 6 9 17 4 12" />
                  </svg>

                  <svg
                    v-if="verifiedAgent && verifiedAgent.isDisabled"
                    class="group relative inline-block mr-2 w-4 h-4 text-gray-200 dark:text-gray-600 stroke-yellow-600"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <title>Ο κωδικός συνεργάτη είναι ανενεργός</title>
                    <path
                      d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"
                    ></path>
                    <line x1="12" y1="9" x2="12" y2="13"></line>
                    <line x1="12" y1="17" x2="12.01" y2="17"></line>
                  </svg>
                </div>
                <div v-if="verifiedAgent && !verifiedAgent.isDisabled">
                  <label for="NNAgentName" class="mx-2"
                    >Επωνυμία Συνεργάτη</label
                  >
                  <input
                    type="text"
                    id="NNAgentName"
                    class="h-8 focus:outline-none w-full px-4"
                    v-bind:value="verifiedAgent?.fullName"
                    disabled="true"
                  />
                </div>
              </div>
            </div>
          </div>
        </custom-card> -->
      </div>

      <!------- //v1.0.3.7	Aλλαγές στη μορφοποίηση ------->

      <!-- //v1.0.1.3  Η σύνοψη ίδια διάσταση και μορφή στις αποθηκευμένες προσφορές και στις απεσταλμένες αιτήσεις -->
      <custom-card title="Σύνοψη" :extraClass="'w-1/4'">
        <div class="pt-4">
          <table>
            <body>
              <!-- <tr>
                                    <td class="tlabel w-40">Εταιρία</td>
                                    <td class="tvalue">{{ getValue('insuranceCompany')}}</td>
                                </tr>
                                <tr>
                                    <td class="tlabel">Κατηγορία</td>
                                    <td class="tvalue">{{ getValue('travelCategory')}}</td>
                                </tr>
                                <tr>
                                    <td class="tlabel">Τύπος</td>
                                    <td class="tvalue">{{ getValue('travelType')}}</td>
                                </tr> -->

              <tr>
                <td class="tlabel font-bold px-2 pt-2">Προσφορά</td>
                <td class="tvalue pt-2 w-96">#{{ policyDetails.quoteId }}</td>
              </tr>

              <tr>
                <td class="tlabel font-bold px-2 pt-2">Πακέτο</td>
                <td class="tvalue">{{ policyDetails.packageName }}</td>
              </tr>

              <tr>
                <td class="tlabel font-bold px-2 pt-2">Σύνολο</td>
                <td class="tvalue">€ {{ policyDetails.price }}</td>
              </tr>

              <hr class="my-4" />
              <tr>
                <td class="tlabel px-2 pt-2">Αναχώριση</td>
                <td class="tvalue w-96 pt-2">
                  {{ policyDetails.fromDate }}
                </td>
              </tr>
              <tr>
                <td class="tlabel px-2 pt-2">Επιστροφή</td>
                <td class="tvalue">{{ policyDetails.toDate }}</td>
              </tr>
              <tr>
                <td class="tlabel px-2 pt-2">Κατοικία</td>
                <td class="tvalue">{{ policyDetails.residence }}</td>
              </tr>
              <tr>
                <td class="tlabel px-2 pt-2">Προορισμός</td>
                <td class="tvalue">{{ policyDetails.destination }}</td>
              </tr>

              <tr>
                <td class="tlabel px-2 pt-2">Ταξιδιώτες</td>
                <td class="tvalue">{{ travellers.length }}</td>
              </tr>

              <template v-if="travellersDataFilled">
                <hr class="my-4" />

                <span class="tlabel px-2 pt-2">Στοιχεία ταξιδιωτών</span>

                <br />
                <br />
                <template
                  class="text-center"
                  v-for="traveller in travellers"
                  v-bind:key="traveller"
                >
                  <span>{{
                    `${traveller.title} ${traveller.firstName} ${traveller.lastName}`
                  }}</span>
                  <br />
                </template>
              </template>

              <!--                       


                                <div v-if="policyDetails.extraCoverages?.length > 0">
                                    <hr class="my-4" />

                                    <tr>
                                        <td class="font-bold text-center p-4" colspan="2">Extras</td>
                                    </tr>


                                    <tr v-for="extraCoverage in policyDetails.extraCoverages" v-bind:key="extraCoverage">
                                        <td class="tlabel w-40 py-2 shrink-0">{{extraCoverage.name}}</td>
                                        <td class="tvalue w-40 py-2">{{extraCoverage.value}}</td>
                                    </tr>
                                </div> -->
            </body>
          </table>
        </div>
      </custom-card>
    
      <!-- //v1.0.1.3  Η σύνοψη ίδια διάσταση και μορφή στις αποθηκευμένες προσφορές και στις απεσταλμένες αιτήσεις -->
    </div>
  </div>
  <!-- <div v-if="gettingQuestions==true" class="h-full pt-60 items-center">
    
      <span class="text-xl ml-72 mr-64 mt-44"
        >Παρακαλώ περιμένετε, γίνεται επικοινωνία με την ασφαλιστική
        εταιρία.</span
      >
   

        <div>
          <svg
            role="status"
            class="inline w-10 h-10 mt-8 text-gray-200 animate-spin dark:text-gray-600"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="#1C64F2"
            />
          </svg>
        </div>
      </div> -->

      <!-- <div>
          <custom-card title="Έγγραφα Συμβολαίου" v-if="docShow==true" class="mt-60" :style="{height:260+'px'}">
            <div class="p-0 bg-white rounded-xl w-1/4 mt-12" :style="{marginLeft:600+'px'}">
              <div class="bg-[#d1182b] rounded-t-xl">
                <h2 class="pt-1 text-white text-xl whitespace-nowrap">
                </h2>
                <div class="text-center py-2 px-4">
                  <h2 class="text-white font-extrabold">
                  </h2>
                </div>
              </div>
              <div class="h-px bg-gray-200"></div>
              <div class="text-center mt-3">
                <div class="p-2 para grid grid-flow-col justify-center content-center">
                    <div
                      class="container group block m-2 rounded text-center justify-center"
                    >
                        <a v-bind:href="certificateLink" target="_blank">
                        <svg
                          version="1.0"
                          xmlns="http://www.w3.org/2000/svg"
                          style="margin: center; fill: #000000"
                          width="50"
                          height="50"
                          viewBox="0 0 920.000000 846.000000"
                          preserveAspectRatio="xMidYMid meet"
                          class="group-hover:stroke-red-500"
                        >
                          <g
                            transform="translate(0.000000,846.000000) scale(0.100000,-0.100000)"
                            fill="#000000"
                            stroke="none"
                          >
                            <path
                              d="M2390 7970 l0 -480 -325 0 -325 0 0 -765 0 -765 325 0 325 0 0 -1900
                                              0 -1900 440 0 440 0 0 115 0 115 -325 0 -325 0 0 1785 0 1785 1285 0 1285 0 0
                                              765 0 765 -1285 0 -1285 0 0 370 0 370 1535 0 1535 0 0 -770 0 -770 770 0 770
                                              0 0 -2150 0 -2150 -325 0 -325 0 0 -115 0 -115 440 0 440 0 0 2383 0 2382
                                              -763 763 -762 762 -1773 0 -1772 0 0 -480z m4145 -440 l610 -610 -613 0 -612
                                              0 0 610 c0 336 1 610 3 610 1 0 277 -274 612 -610z m-3759 -364 c122 -38 186
                                              -127 186 -260 0 -136 -50 -225 -155 -274 -49 -23 -150 -41 -229 -42 l-48 0 0
                                              -160 0 -160 -100 0 -100 0 0 453 c0 250 3 457 8 462 13 14 382 -2 438 -19z
                                              m711 14 c209 -22 330 -114 385 -293 27 -91 22 -263 -11 -347 -46 -118 -135
                                              -200 -261 -242 -55 -18 -96 -22 -267 -26 l-203 -4 0 454 c0 250 3 458 7 461
                                              10 10 245 8 350 -3z m1113 -76 l0 -84 -165 0 -165 0 0 -115 0 -115 155 0 155
                                              0 0 -80 0 -80 -155 0 -155 0 0 -180 0 -180 -95 0 -95 0 0 460 0 461 260 -1
                                              260 -1 0 -85z"
                            />
                            <path
                              d="M2530 6890 l0 -130 66 0 c114 0 166 41 167 132 1 89 -47 128 -160
                                              128 l-73 0 0 -130z"
                            />
                            <path
                              d="M3330 6725 l0 -298 86 7 c99 7 155 28 201 76 82 84 94 302 23 414
                                              -43 67 -92 89 -212 94 l-98 4 0 -297z"
                            />
                            <path
                              d="M4140 3770 l0 -920 -382 0 -383 0 175 -173 c96 -94 378 -372 625
                                              -616 248 -245 518 -512 601 -594 l150 -148 495 488 c271 268 620 613 774 765
                                              l280 277 -382 1 -383 0 0 920 0 920 -785 0 -785 0 0 -920z"
                            />
                          </g>
                        </svg>
                        <div class="group-hover:underline mr-5 grid grid-flow-col para2">
                        {{ certificate }}
                        </div>
                      </a>
                    </div>
                    <div
                      class="container group block m-2 rounded text-center justify-left"
                    >
                        <a v-bind:href="PWLink" target="_blank">
                        <svg
                          version="1.0"
                          xmlns="http://www.w3.org/2000/svg"
                          style="margin: center; fill: #000000"
                          width="50"
                          height="50"
                          viewBox="0 0 920.000000 846.000000"
                          preserveAspectRatio="xMidYMid meet"
                          class="group-hover:stroke-red-500"
                        >
                          <g
                            transform="translate(0.000000,846.000000) scale(0.100000,-0.100000)"
                            fill="#000000"
                            stroke="none"
                          >
                            <path
                              d="M2390 7970 l0 -480 -325 0 -325 0 0 -765 0 -765 325 0 325 0 0 -1900
                                              0 -1900 440 0 440 0 0 115 0 115 -325 0 -325 0 0 1785 0 1785 1285 0 1285 0 0
                                              765 0 765 -1285 0 -1285 0 0 370 0 370 1535 0 1535 0 0 -770 0 -770 770 0 770
                                              0 0 -2150 0 -2150 -325 0 -325 0 0 -115 0 -115 440 0 440 0 0 2383 0 2382
                                              -763 763 -762 762 -1773 0 -1772 0 0 -480z m4145 -440 l610 -610 -613 0 -612
                                              0 0 610 c0 336 1 610 3 610 1 0 277 -274 612 -610z m-3759 -364 c122 -38 186
                                              -127 186 -260 0 -136 -50 -225 -155 -274 -49 -23 -150 -41 -229 -42 l-48 0 0
                                              -160 0 -160 -100 0 -100 0 0 453 c0 250 3 457 8 462 13 14 382 -2 438 -19z
                                              m711 14 c209 -22 330 -114 385 -293 27 -91 22 -263 -11 -347 -46 -118 -135
                                              -200 -261 -242 -55 -18 -96 -22 -267 -26 l-203 -4 0 454 c0 250 3 458 7 461
                                              10 10 245 8 350 -3z m1113 -76 l0 -84 -165 0 -165 0 0 -115 0 -115 155 0 155
                                              0 0 -80 0 -80 -155 0 -155 0 0 -180 0 -180 -95 0 -95 0 0 460 0 461 260 -1
                                              260 -1 0 -85z"
                            />
                            <path
                              d="M2530 6890 l0 -130 66 0 c114 0 166 41 167 132 1 89 -47 128 -160
                                              128 l-73 0 0 -130z"
                            />
                            <path
                              d="M3330 6725 l0 -298 86 7 c99 7 155 28 201 76 82 84 94 302 23 414
                                              -43 67 -92 89 -212 94 l-98 4 0 -297z"
                            />
                            <path
                              d="M4140 3770 l0 -920 -382 0 -383 0 175 -173 c96 -94 378 -372 625
                                              -616 248 -245 518 -512 601 -594 l150 -148 495 488 c271 268 620 613 774 765
                                              l280 277 -382 1 -383 0 0 920 0 920 -785 0 -785 0 0 -920z"
                            />
                          </g>
                        </svg>
                        <div class="group-hover:underline mr-5 grid grid-flow-col para2">
                        {{ PW }}
                        </div>
                      </a>
                    </div>
                    <div
                      class="container group block m-2 rounded text-center justify-left"
                    >
                        <a v-bind:href="SILink" target="_blank">
                        <svg
                          version="1.0"
                          xmlns="http://www.w3.org/2000/svg"
                          style="margin: center; fill: #000000"
                          width="50"
                          height="50"
                          viewBox="0 0 920.000000 846.000000"
                          preserveAspectRatio="xMidYMid meet"
                          class="group-hover:stroke-red-500"
                        >
                          <g
                            transform="translate(0.000000,846.000000) scale(0.100000,-0.100000)"
                            fill="#000000"
                            stroke="none"
                          >
                            <path
                              d="M2390 7970 l0 -480 -325 0 -325 0 0 -765 0 -765 325 0 325 0 0 -1900
                                              0 -1900 440 0 440 0 0 115 0 115 -325 0 -325 0 0 1785 0 1785 1285 0 1285 0 0
                                              765 0 765 -1285 0 -1285 0 0 370 0 370 1535 0 1535 0 0 -770 0 -770 770 0 770
                                              0 0 -2150 0 -2150 -325 0 -325 0 0 -115 0 -115 440 0 440 0 0 2383 0 2382
                                              -763 763 -762 762 -1773 0 -1772 0 0 -480z m4145 -440 l610 -610 -613 0 -612
                                              0 0 610 c0 336 1 610 3 610 1 0 277 -274 612 -610z m-3759 -364 c122 -38 186
                                              -127 186 -260 0 -136 -50 -225 -155 -274 -49 -23 -150 -41 -229 -42 l-48 0 0
                                              -160 0 -160 -100 0 -100 0 0 453 c0 250 3 457 8 462 13 14 382 -2 438 -19z
                                              m711 14 c209 -22 330 -114 385 -293 27 -91 22 -263 -11 -347 -46 -118 -135
                                              -200 -261 -242 -55 -18 -96 -22 -267 -26 l-203 -4 0 454 c0 250 3 458 7 461
                                              10 10 245 8 350 -3z m1113 -76 l0 -84 -165 0 -165 0 0 -115 0 -115 155 0 155
                                              0 0 -80 0 -80 -155 0 -155 0 0 -180 0 -180 -95 0 -95 0 0 460 0 461 260 -1
                                              260 -1 0 -85z"
                            />
                            <path
                              d="M2530 6890 l0 -130 66 0 c114 0 166 41 167 132 1 89 -47 128 -160
                                              128 l-73 0 0 -130z"
                            />
                            <path
                              d="M3330 6725 l0 -298 86 7 c99 7 155 28 201 76 82 84 94 302 23 414
                                              -43 67 -92 89 -212 94 l-98 4 0 -297z"
                            />
                            <path
                              d="M4140 3770 l0 -920 -382 0 -383 0 175 -173 c96 -94 378 -372 625
                                              -616 248 -245 518 -512 601 -594 l150 -148 495 488 c271 268 620 613 774 765
                                              l280 277 -382 1 -383 0 0 920 0 920 -785 0 -785 0 0 -920z"
                            />
                          </g>
                        </svg>
                        <div class="group-hover:underline mr-5 grid grid-flow-col para2">
                        {{ SI }}
                        </div>
                      </a>
                    </div>
                    <div
                      class="container group block m-2 rounded text-center justify-left"
                    >
                        <a v-bind:href="KFLink" target="_blank">
                        <svg
                          version="1.0"
                          xmlns="http://www.w3.org/2000/svg"
                          style="margin: center; fill: #000000"
                          width="50"
                          height="50"
                          viewBox="0 0 920.000000 846.000000"
                          preserveAspectRatio="xMidYMid meet"
                          class="group-hover:stroke-red-500"
                        >
                          <g
                            transform="translate(0.000000,846.000000) scale(0.100000,-0.100000)"
                            fill="#000000"
                            stroke="none"
                          >
                            <path
                              d="M2390 7970 l0 -480 -325 0 -325 0 0 -765 0 -765 325 0 325 0 0 -1900
                                              0 -1900 440 0 440 0 0 115 0 115 -325 0 -325 0 0 1785 0 1785 1285 0 1285 0 0
                                              765 0 765 -1285 0 -1285 0 0 370 0 370 1535 0 1535 0 0 -770 0 -770 770 0 770
                                              0 0 -2150 0 -2150 -325 0 -325 0 0 -115 0 -115 440 0 440 0 0 2383 0 2382
                                              -763 763 -762 762 -1773 0 -1772 0 0 -480z m4145 -440 l610 -610 -613 0 -612
                                              0 0 610 c0 336 1 610 3 610 1 0 277 -274 612 -610z m-3759 -364 c122 -38 186
                                              -127 186 -260 0 -136 -50 -225 -155 -274 -49 -23 -150 -41 -229 -42 l-48 0 0
                                              -160 0 -160 -100 0 -100 0 0 453 c0 250 3 457 8 462 13 14 382 -2 438 -19z
                                              m711 14 c209 -22 330 -114 385 -293 27 -91 22 -263 -11 -347 -46 -118 -135
                                              -200 -261 -242 -55 -18 -96 -22 -267 -26 l-203 -4 0 454 c0 250 3 458 7 461
                                              10 10 245 8 350 -3z m1113 -76 l0 -84 -165 0 -165 0 0 -115 0 -115 155 0 155
                                              0 0 -80 0 -80 -155 0 -155 0 0 -180 0 -180 -95 0 -95 0 0 460 0 461 260 -1
                                              260 -1 0 -85z"
                            />
                            <path
                              d="M2530 6890 l0 -130 66 0 c114 0 166 41 167 132 1 89 -47 128 -160
                                              128 l-73 0 0 -130z"
                            />
                            <path
                              d="M3330 6725 l0 -298 86 7 c99 7 155 28 201 76 82 84 94 302 23 414
                                              -43 67 -92 89 -212 94 l-98 4 0 -297z"
                            />
                            <path
                              d="M4140 3770 l0 -920 -382 0 -383 0 175 -173 c96 -94 378 -372 625
                                              -616 248 -245 518 -512 601 -594 l150 -148 495 488 c271 268 620 613 774 765
                                              l280 277 -382 1 -383 0 0 920 0 920 -785 0 -785 0 0 -920z"
                            />
                          </g>
                        </svg>
                        <div class="group-hover:underline mr-5 grid grid-flow-col para2">
                        {{ KF }}
                        </div>
                      </a>
                    </div>
                    <div
                      class="container group block m-2 rounded text-center justify-left"
                    >
                        <a v-bind:href="IPIDLink" target="_blank">
                        <svg
                          version="1.0"
                          xmlns="http://www.w3.org/2000/svg"
                          style="margin: center; fill: #000000"
                          width="50"
                          height="50"
                          viewBox="0 0 920.000000 846.000000"
                          preserveAspectRatio="xMidYMid meet"
                          class="group-hover:stroke-red-500"
                        >
                          <g
                            transform="translate(0.000000,846.000000) scale(0.100000,-0.100000)"
                            fill="#000000"
                            stroke="none"
                          >
                            <path
                              d="M2390 7970 l0 -480 -325 0 -325 0 0 -765 0 -765 325 0 325 0 0 -1900
                                              0 -1900 440 0 440 0 0 115 0 115 -325 0 -325 0 0 1785 0 1785 1285 0 1285 0 0
                                              765 0 765 -1285 0 -1285 0 0 370 0 370 1535 0 1535 0 0 -770 0 -770 770 0 770
                                              0 0 -2150 0 -2150 -325 0 -325 0 0 -115 0 -115 440 0 440 0 0 2383 0 2382
                                              -763 763 -762 762 -1773 0 -1772 0 0 -480z m4145 -440 l610 -610 -613 0 -612
                                              0 0 610 c0 336 1 610 3 610 1 0 277 -274 612 -610z m-3759 -364 c122 -38 186
                                              -127 186 -260 0 -136 -50 -225 -155 -274 -49 -23 -150 -41 -229 -42 l-48 0 0
                                              -160 0 -160 -100 0 -100 0 0 453 c0 250 3 457 8 462 13 14 382 -2 438 -19z
                                              m711 14 c209 -22 330 -114 385 -293 27 -91 22 -263 -11 -347 -46 -118 -135
                                              -200 -261 -242 -55 -18 -96 -22 -267 -26 l-203 -4 0 454 c0 250 3 458 7 461
                                              10 10 245 8 350 -3z m1113 -76 l0 -84 -165 0 -165 0 0 -115 0 -115 155 0 155
                                              0 0 -80 0 -80 -155 0 -155 0 0 -180 0 -180 -95 0 -95 0 0 460 0 461 260 -1
                                              260 -1 0 -85z"
                            />
                            <path
                              d="M2530 6890 l0 -130 66 0 c114 0 166 41 167 132 1 89 -47 128 -160
                                              128 l-73 0 0 -130z"
                            />
                            <path
                              d="M3330 6725 l0 -298 86 7 c99 7 155 28 201 76 82 84 94 302 23 414
                                              -43 67 -92 89 -212 94 l-98 4 0 -297z"
                            />
                            <path
                              d="M4140 3770 l0 -920 -382 0 -383 0 175 -173 c96 -94 378 -372 625
                                              -616 248 -245 518 -512 601 -594 l150 -148 495 488 c271 268 620 613 774 765
                                              l280 277 -382 1 -383 0 0 920 0 920 -785 0 -785 0 0 -920z"
                            />
                          </g>
                        </svg>
                        <div class="group-hover:underline mr-5 grid grid-flow-col para2">
                        {{ IPID }}
                        </div>
                      </a>
                    </div>
                </div>
              </div>
            </div>
            <span class="mt-24">Εάν επιθυμείτε να επιστρέψετε στην αρχική σελίδα, Παρακαλώ πατήστε <a class="text-red-600" href="/">εδώ</a></span>
          </custom-card>
          
      </div> -->
  <br />
 

  <div class="w-full text-center content-center justify-center">
    <br />
    <span class="k-form-separator mt-8"></span>
    <div class="flex flex-col k-form-buttons">
      <span class="mr-96">
        <spinner-button
          ref="sendRequestButton"
          buttonClass="w-120 px-2 py-1 text-white transition-colors duration-300 bg-[#d1182b] enabled:bg-[#d1182b] rounded-md shadow enabled:hover:bg-red-500 focus:outline-none"
          idleText="Αποστολή ειδοποίησης πληρωμής"
          @idleClick="submitButtonClickTest"
        />
        <!-- submitButtonClick -->
        <!-- :disabled="disableSubmitButton" -->
      </span>
    </div>
  </div>
    
</template>

<!--//v1.0.3.7  Aλλαγές στη μορφοποίηση της σύνοψης-->
<style scoped>
.tlabel {
  text-align: left;
  vertical-align: middle;
  font-weight: bolder;
}
.tvalue {
  text-align: right;
  vertical-align: middle;
}
</style>

<!--//v1.0.3.7  Aλλαγές στη μορφοποίηση της σύνοψης-->

<script>
import { Field, FormElement } from "@progress/kendo-vue-form";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { Input } from "@progress/kendo-vue-inputs";
import { DatePicker } from "@progress/kendo-vue-dateinputs";
import CustomCard from "@/components/CustomCard.vue";
import { Button } from "@progress/kendo-vue-buttons";
import SpinnerButton from "@/components/SpinnerButton.vue";
import QuoteCardPolicy from "../NewQuoteRequest/QuoteCardPolicy.vue";

import apiClient from "@/apiClient";
import { requiredValidator } from "./validators";
export default {
  name: "NewPolicyRequestForm",

  components: {
    "k-form-field": Field,
    "k-form-element": FormElement,
    "k-input": Input,
    "k-drop-down-list": DropDownList,
    "k-date-picker": DatePicker,
    "k-button": Button,
    CustomCard,
    SpinnerButton,
    QuoteCardPolicy
  },

  inject: ["$appConfig", "kendoForm"],

  props: ["policyDetailsProp"],


 

  data: function () {
    return {
      value: null,
      legalForms: ["Individual", "Company"],
      titles: ["Mr.", "Ms.", "Mrs."],
      travellers: [],
      requiredValidator,
      verifiedAgent: null,
      isNN: false,
      sobo: [],
      NNAgntsobo: [],
      agentCode: null,
      policyDetails: {
        quoteId: null,
        packageName: null,
        price: null,
        origin: null,
        destination: null,
        fromDate: null,
        toDate: null,
        personsPerAgeBand: [],
      },
      gettingQuestions: false,

      customer: {
        legalForm: null,
        name: null,
        vatNumber: null,
        address: null,
        postalCode: null,
        telephone: null,
        email: null,
      },
      quoteDocs:[],
      quoteLinks:[],
      certificate:null,
      certificateLink:null,
      PW:null,
      PWLink:null,
      SI:null,
      SILink:null,
      KF:null,
      KFLink:null,
      IPID:null,
      IPIDLink:null,
      docShow:false,
      formShow:false
    };
  },

  mounted() {
    // console.log(this.policyDetailsProp);
    this.formShow=true;
    this.policyDetails = this.$props.policyDetailsProp;

    this.generateTravellers();
  },

  methods: {
    checkDigit(e){
      if(e.key.length===1 && isNaN(Number(e.key))){
        e.preventDefault();
      }
    },
    stepActivated() {
      this.getSoboFields();
    },
    /// sell on behalf of query
    async getSoboFields() {
      const token = this.kendoForm.values.apiToken;
      
      const response = await apiClient.getSoboDetails(token);
      this.sobo = response.map(function (x) {
        return { value: x.SAP_partner_code, label: x.full_name };
      });
      // console.log(this.sobo);
    },
    isNNAgent() {
      if (this.kendoForm.values.sobo.value == "P.00522") {
        this.isNN = true;
        // this.loadNNAgentValues();
        //console.log(this.sobo);
        if (
          this.kendoForm.errors.nnAgentCode != "" &&
          this.kendoForm.values.nnAgentCode != null
        )
          this.kendoForm.errors.nnAgentCode = "";
        if (this.kendoForm.errors.nnAgentCode != "") return false;
        if (this.kendoForm.values.nnAgentCode == null) return false;
      } else {
        this.isNN = false;
        this.agentCode = null;
        this.verifiedAgent = [];
      }
    },
    loadNNAgentValues() {
      const url =
        "https://ikeportal.gkaravias.gr/api/reports/dropdown-filter-values";
      //console.log("this.sobo");
      // console.log(this.$store.state.token)
      this.axios
        .get(url, {
          headers: { Authorization: "Bearer " + this.$store.state.token },
        })
        .then((response) => {
          this.NNAgntsobo = response.data.allNNAgentCod;
        });
    },
    
    async verify() {
      // console.log("1");
      const agent = await this.getAgent(this.agentCode);
      this.verifiedAgent = agent;
      // console.log(this.agentCode);
    },

    async getAgent(code) {
      if (code?.length > 5 == false) return null;

      // const url = "http://192.168.0.173:5287/api/agents";
      // console.log("1");
      const url = this.request_NN_Agents_Url.href;
      // console.log(url);
      try {
        const response = await this.axios.get(url, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
          params: { agentCode: code },
        });
        // idresourceIdentifier;

        return response.data;
      } catch {
        return null;
      }
    },
    created() {
      this.threelettersAgentCode = this.filterValues?.allAgentCodes.slice();
      // console.log(this.filterValues?.allAgentCodes.allNNAgentCod);
    },

    getAge(dateOfBirth) {
      if (dateOfBirth == null) return "";

      var ageDifMs = Date.now() - dateOfBirth;
      var ageDate = new Date(ageDifMs); // miliseconds from epoch

      const age = Math.abs(ageDate.getUTCFullYear() - 1970);

      // return `${age}-${age+1}`;

      // return `${age}-${age+1}`;
      //v1.0.2.8  Έλεγχος ηλικίας (if is older than 120) να μην εμφαβίζεται στο td element μετα την επιλογή ημερομηνίας γέννησης

      if (age > 120) {
        return "";
      } else {
        return age;
      }

      //v1.0.2.8  Έλεγχος ηλικίας (if is older than 120) να μην εμφαβίζεται στο td element μετα την επιλογή ημερομηνίας γέννησης
    },

    generateTravellers() {
      let travellers = [];

      if (this.policyDetails == null) return;

      this.policyDetails.personsPerAgeBand.forEach(function (item) {
        for (let i = 1; i <= item.persons; i++) {
          let thisTraveller = {
            title: null,
            firstName: null,
            lastName: null,
            dateOfBirth: null,
            ageBand: item.ageBand,
          };

          travellers.push(thisTraveller);
        }
      });

      this.travellers = travellers;
    },

    maximumDateOfBirth(traveller) {
      function subYears(years) {
        var today = new Date();

        var year = today.getFullYear();
        var month = today.getMonth();
        var day = today.getDate();
        var c = new Date(year - years, month, day);

        return c;
      }

      const c = subYears(traveller.ageBand.minAge);

      return c;
    },

    minimumDateOfBirth(traveller) {
      function subYears(years) {
        var today = new Date();

        var year = today.getFullYear();
        var month = today.getMonth();
        var day = today.getDate();
        var c = new Date(year - years, month, day);

        return c;
      }

      return subYears(traveller.ageBand.maxAge);
    },

    submitButtonClick() {
      this.$refs.sendRequestButton.setStateActive();
      
      // kua api update offer
      const policyholderDetails = {
        LegalForm: this.customer.legalForm,
        FullOrCompanyName: this.customer.name,
        VATNumber: this.customer.vatNumber,
        email: this.customer.email,
        address: this.customer.address,
        postcode: this.customer.postalCode,
        telephone: this.customer.telephone,
      };

    
      // console.log(this.customer.vatNumber);
     
      const travellers = this.travellers.map((x) => ({
        lead: "",
        title: x.title,
        firstname: x.firstName,
        lastname: x.lastName,
        dob: x.dateOfBirth.toLocaleDateString("el-GR", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
        }),
        // age: this.getAge(x.dob),
        AgeBandId: x.ageBand.id,
      }));
      // if(this.kendoForm.values.sobo.value == ""){
      //   console.log("this.sobo is empty");
      // }

      const issuePayload={
        token: this.kendoForm.values.apiToken,
        quoteID: this.policyDetails.quoteId,
        screeningQuestionsAnswers: {
          screeningQuestionAnswer:[{
            questionNumber:1,
            answer:"yes"
          }]
        } 
      }

      const documentsPolicyPayload={
        token: this.kendoForm.values.apiToken
      }
      // console.log(documentsPolicyPayload);


      if(this.kendoForm.values.sobo==undefined){
        const apiUpdatePayload = {
          token: this.kendoForm.values.apiToken,
          quoteID: this.policyDetails.quoteId,
          travellers: travellers,
          sell_on_behalf_of: "",
          PolicyholderDetails: [policyholderDetails],
        };
        const targetUrl = this.requestTravelPolicyIssueUrl.href;
        // console.log(apiUpdatePayload);
        this.axios
          .post(targetUrl, apiUpdatePayload)
          .then((response) => {
            this.$refs.sendRequestButton.setStateIdle();
            if (response.data.ErrorCode)
              this.$swal.fire("Σφάλμα", response.data.ErrorDescription, "error");
            else
              this.$swal
                .fire("Επιτυχία", "Το αίτημα καταχωρήθηκε", "success")
                .then(() => this.$router.push("/travel/policy-requests"));
          })
          .catch((error) => {
            this.$refs.sendRequestButton.setStateIdle();
            this.$swal.fire(
              "Σφάλμα",
              "Το αίτημα δεν μπορεί να καταχωρηθεί",
              "error"
            );
          });
      }else{
        const apiUpdatePayload = {
          token: this.kendoForm.values.apiToken,
          quoteID: this.policyDetails.quoteId,
          travellers: travellers,
          sell_on_behalf_of: this.kendoForm.values.sobo.value,
          PolicyholderDetails: [policyholderDetails],
        };
        const targetUrl = this.requestTravelPolicyIssueUrl.href;
        // console.log(apiUpdatePayload);
        this.axios
          .post(targetUrl, apiUpdatePayload)
          .then((response) => {
            this.$refs.sendRequestButton.setStateIdle();
            if (response.data.ErrorCode)
              this.$swal.fire("Σφάλμα", response.data.ErrorDescription, "error");
            else
              this.$swal
                .fire("Επιτυχία", "Το αίτημα καταχωρήθηκε", "success")
                .then(() => this.$router.push("/travel/policy-requests"));
          })
          .catch((error) => {
            this.$refs.sendRequestButton.setStateIdle();
            this.$swal.fire(
              "Σφάλμα",
              "Το αίτημα δεν μπορεί να καταχωρηθεί",
              "error"
            );
          });
      }

    
      // const targetUrl = this.requestTravelPolicyIssueUrl.href;
      // console.log(apiUpdatePayload);
      // this.axios
      //   .post(targetUrl, apiUpdatePayload)
      //   .then((response) => {
      //     this.$refs.sendRequestButton.setStateIdle();
      //     if (response.data.ErrorCode)
      //       this.$swal.fire("Σφάλμα", response.data.ErrorDescription, "error");
      //     else
      //       this.$swal
      //         .fire("Επιτυχία", "Το αίτημα καταχωρήθηκε", "success")
      //         .then(() => this.$router.push("/travel/policy-requests"));
      //   })
      //   .catch((error) => {
      //     this.$refs.sendRequestButton.setStateIdle();
      //     this.$swal.fire(
      //       "Σφάλμα",
      //       "Το αίτημα δεν μπορεί να καταχωρηθεί",
      //       "error"
      //     );
      //   });
    },
    submitButtonClickTest() {
      this.$refs.sendRequestButton.setStateActive();
      
      // kua api update offer
      const policyholderDetails = {
        LegalForm: this.customer.legalForm,
        FullOrCompanyName: this.customer.name,
        VATNumber: this.customer.vatNumber,
        email: this.customer.email,
        address: this.customer.address,
        postcode: this.customer.postalCode,
        telephone: this.customer.telephone,
      };

    
      // console.log(this.customer.vatNumber);
     
      const travellers = this.travellers.map((x) => ({
        lead: "",
        title: x.title,
        firstname: x.firstName,
        lastname: x.lastName,
        dob: x.dateOfBirth.toLocaleDateString("el-GR", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
        }),
        // age: this.getAge(x.dob),
        AgeBandId: x.ageBand.id,
      }));
      // if(this.kendoForm.values.sobo.value == ""){
      //   console.log("this.sobo is empty");
      // }

      const issuePayload={
        token: this.kendoForm.values.apiToken,
        quoteID: this.policyDetails.quoteId,
        screeningQuestionsAnswers: {
          screeningQuestionAnswer:[{
            questionNumber:1,
            answer:"yes"
          }]
        } 
      }

    
      


      if(this.kendoForm.values.sobo==undefined){
        const apiUpdatePayload = {
          token: this.kendoForm.values.apiToken,
          quoteID: this.policyDetails.quoteId,
          travellers: travellers,
          sell_on_behalf_of: "",
          PolicyholderDetails: [policyholderDetails],
        };
        const targetUrl = this.requestTravelPolicyIssueUrl.href;
        // console.log(apiUpdatePayload);
        this.axios
          .post(targetUrl, apiUpdatePayload)
          .then((response) => {
            this.$refs.sendRequestButton.setStateIdle();
            if (response.data.ErrorCode)
              this.$swal.fire("Σφάλμα", response.data.ErrorDescription, "error");
            else
            this.$swal
                .fire("Επιτυχία", "Το αίτημα καταχωρήθηκε", "success")
                // .then(() => this.$router.push("/travel/policy-requests"));
                .then(() =>{
                  this.gettingQuestions=true;
                  this.$router.push("/travel/policy-requests")
                  // this.issuePolicy();
                });
          })
          .catch((error) => {
            this.$refs.sendRequestButton.setStateIdle();
            this.$swal.fire(
              "Σφάλμα",
              "Το αίτημα δεν μπορεί να καταχωρηθεί",
              "error"
            );
          });
      }else{
        const apiUpdatePayload = {
          token: this.kendoForm.values.apiToken,
          quoteID: this.policyDetails.quoteId,
          travellers: travellers,
          sell_on_behalf_of: this.kendoForm.values.sobo.value,
          PolicyholderDetails: [policyholderDetails],
        };
        const targetUrl = this.requestTravelPolicyIssueUrl.href;
        // console.log(apiUpdatePayload);
        this.axios
          .post(targetUrl, apiUpdatePayload)
          .then((response) => {
            this.$refs.sendRequestButton.setStateIdle();
            if (response.data.ErrorCode)
              this.$swal.fire("Σφάλμα", response.data.ErrorDescription, "error");
            else
              this.$swal
                .fire("Επιτυχία", "Το αίτημα καταχωρήθηκε", "success")
                // .then(() => this.$router.push("/travel/policy-requests"));
                .then(() =>{
                  this.formShow=false;
                  // this.issuePolicy();
                });

          })
          .catch((error) => {
            this.$refs.sendRequestButton.setStateIdle();
            this.$swal.fire(
              "Σφάλμα",
              "Το αίτημα δεν μπορεί να καταχωρηθεί",
              "error"
            );
          });
      }

    
      // const targetUrl = this.requestTravelPolicyIssueUrl.href;
      // console.log(apiUpdatePayload);
      // this.axios
      //   .post(targetUrl, apiUpdatePayload)
      //   .then((response) => {
      //     this.$refs.sendRequestButton.setStateIdle();
      //     if (response.data.ErrorCode)
      //       this.$swal.fire("Σφάλμα", response.data.ErrorDescription, "error");
      //     else
      //       this.$swal
      //         .fire("Επιτυχία", "Το αίτημα καταχωρήθηκε", "success")
      //         .then(() => this.$router.push("/travel/policy-requests"));
      //   })
      //   .catch((error) => {
      //     this.$refs.sendRequestButton.setStateIdle();
      //     this.$swal.fire(
      //       "Σφάλμα",
      //       "Το αίτημα δεν μπορεί να καταχωρηθεί",
      //       "error"
      //     );
      //   });
    },
    // submitButtonClickTest(){
    //   console.log(this.kendoForm.values);
    // },
    getPolicyDocuments(){
        const documentsPolicyPayload={
          token: this.kendoForm.values.apiToken
        }
        const targetUrl = this.policyDocumentsUrl.href;

        this.axios
            .post(targetUrl, documentsPolicyPayload)
            .then((response) => {
              // this.$refs.sendRequestButton.setStateIdle();
              if (response.data.ErrorCode){
                this.$swal.fire("Σφάλμα", response.data.ErrorDescription, "error");
              }
              else{
                this.quoteDocs.push(Object.keys(response.data.GetPolicyDocumentsResult));
                this.quoteLinks.push(Object.values(response.data.GetPolicyDocumentsResult))
                // console.log(response.data.GetPolicyDocumentsResult);
                // this.$swal
                //   .fire("Επιτυχία", "Το αίτημα καταχωρήθηκε", "success")
                  // .then(() => this.$router.push("/travel/policy-requests"));
                  this.certificate=this.quoteDocs[0][3];
                  this.certificateLink=this.quoteLinks[0][3];
                  this.PWLink = this.quoteLinks[0][4];
                  this.PW=this.quoteDocs[0][4];
                  this.SILink=this.quoteLinks[0][5];
                  this.SI = this.quoteDocs[0][5];
                  this.KFLink = this.quoteLinks[0][6];
                  this.KF = this.quoteDocs[0][6];
                  this.IPIDLink=this.quoteLinks[0][7];
                  this.IPID=this.quoteDocs[0][7];
                  this.formShow=false;
                  this.gettingQuestions=false;
                  this.docShow=true;
                  this.formShow=false;
                  
                  
                  this.customer.legalForm=null
                  this.customer.name=null
                  this.customer.vatNumber=null
                  this.customer.address=null
                  this.customer.postalCode=null
                  this.customer.email=null
                  this.customer.telephone=null
              }
             
            })
            .catch((error) => {
              // this.$refs.sendRequestButton.setStateIdle();
              this.$swal.fire(
                "Σφάλμα",
                "Το αίτημα δεν μπορεί να καταχωρηθεί",
                "error"
              );
            });

      },
      issuePolicy(){
        const documentsPolicyPayload={
          token: this.kendoForm.values.apiToken,
          quoteID: this.policyDetails.quoteId,
          screeningQuestionsAnswers:{
              screeningQuestionAnswer: [{
                questionNumber: 1,
				        answer: "Yes"
              }]
          }
        }

   
        const targetUrl = this.requestQuoteIssueUrl.href;
        this.axios
            .post(targetUrl, documentsPolicyPayload)
            .then((response) => {
              // this.$refs.sendRequestButton.setStateIdle();
              if (response.data.ErrorCode){
                this.$swal.fire("Σφάλμα", response.data.ErrorDescription, "error");
              }
              else{
               
                  this.quoteDocs.push(Object.keys(response.data));
                  this.quoteLinks.push(Object.values(response.data))
                  this.certificate=this.quoteDocs[0][3];
                  this.certificateLink=this.quoteLinks[0][3];
                  this.PWLink = this.quoteLinks[0][4];
                  this.PW=this.quoteDocs[0][4];
                  this.SILink=this.quoteLinks[0][5];
                  this.SI = this.quoteDocs[0][5];
                  this.KFLink = this.quoteLinks[0][6];
                  this.KF = this.quoteDocs[0][6];
                  this.IPIDLink=this.quoteLinks[0][7];
                  this.IPID=this.quoteDocs[0][7];
                  this.formShow=false;
                  this.gettingQuestions=false;
                  this.docShow=true;
                  this.formShow=false;
                  
                  
                  this.customer.legalForm=null
                  this.customer.name=null
                  this.customer.vatNumber=null
                  this.customer.address=null
                  this.customer.postalCode=null
                  this.customer.email=null
                  this.customer.telephone=null
                  
              }
            })

      },
  },


  computed: {
    request_NN_Agents_Url() {
      // console.log(this.$appConfig);
      return new URL("/api/agents", this.$appConfig.NN_API_BASE_URL);
    },
    requestTravelPolicyIssueUrl() {
      return new URL(
        "/travel/update_policy_details",
        this.$appConfig.KUA_API_BASE_URL
      );
    },

    requestQuoteIssueUrl() {
      return new URL(
        "/travel/issue",
        this.$appConfig.KUA_API_BASE_URL
      );
    
    },
    policyDocumentsUrl(){
        return new URL(
          "/travel/getPolicyDocuments",
          this.$appConfig.KUA_API_BASE_URL
        );
      },

    travellersFullNames() {
      return this.travellers
        .filter(
          (x) =>
            x.firstName != null &&
            x.lastName != null &&
            this.getAge(x.dateOfBirth) >= 18
        )
        .map((x) => `${x.title} ${x.firstName} ${x.lastName}`);
    },

    customerDataFilled() {
      return (
        this.customer.address?.length > 3 &&
        this.customer.postalCode?.length > 2 &&
        this.customer.telephone?.length > 9 &&
        this.customer.email?.length > 5 &&
        ((this.customer.legalForm == "Individual" &&
          this.customer.name?.length > 3) ||
          (this.customer.legalForm == "Company" &&
            this.customer.vatNumber?.length > 8))
      );
    },

    travellersDataFilled() {
      const travellerDataFilled = (traveller) =>
        traveller.title?.length > 0 &&
        traveller.firstName?.length > 1 &&
        traveller.lastName?.length > 1 &&
        traveller.dateOfBirth != null;

      const travellerChecks = this.travellers.map((x) =>
        travellerDataFilled(x)
      );

      if (travellerChecks.filter((x) => x == false).length > 0) return false;

      return true;
    },

    disableSubmitButton() {
      if (this.customerDataFilled == false) return true;

      if (this.travellersDataFilled == false) return true;

      return false;
    },
  },

  watch: {
    "customer.legalForm": function () {
      this.stepActivated();
      this.customer.name = null;
      this.customer.vatNumber = null;
    },
  },
};
</script>
