<template>
  <!--------------------//v1.0.3.4 Αλλαγή στη στοίχιση----------------------->
  <div class="flex flex-row w-full p-4 mt-6">
    <!--------------------//v1.0.3.4 Αλλαγή στη στοίχιση----------------------->
    <k-dialog
      v-if="showSuccessDialog"
      title="Ενημέρωση"
      @close="toggleSucessDialog"
    >
      <p :style="{ margin: '25px', textAlign: 'center' }">
        Το αίτημα καταχωρήθηκε με επιτυχία, και θα αποσταλεί το σχετικό email.
      </p>
      <k-dialog-actions-bar>
        <k-button @click="toggleSuccessDialog">Εντάξει</k-button>
      </k-dialog-actions-bar>
    </k-dialog>
    <k-dialog
      v-if="visibleDialog"
      title="Επαναποστολή κωδικού πληρωμής"
      @close="toggleDialog(false)"
    >
      <p :style="{ margin: '25px', textAlign: 'center' }">
        Παρακαλώ εισάγετε την διεύθυνση email
      </p>

      <div
        class="flex items-center bg-white rounded shadow-md mb-4 whitespace-nowrap"
      >
        <input
          type="text"
          class="h-8 focus:outline-none w-60 px-4"
          v-model="dialogEmailInput"
          v-on:keyup="verifyDialogEmail"
          v-on:blur="verifyDialogEmail"
        />
        <div>
          <svg
            v-if="verifiedDialogEmail"
            class="inline mr-2 w-4 h-4 text-gray-200 dark:text-gray-600 stroke-green-500"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <polyline points="20 6 9 17 4 12" />
          </svg>

          <svg
            v-else
            class="group relative inline-block mr-2 w-4 h-4 text-gray-200 dark:text-gray-600 stroke-yellow-600"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <title>Λάθος email</title>
            <path
              d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"
            ></path>
            <line x1="12" y1="9" x2="12" y2="13"></line>
            <line x1="12" y1="17" x2="12.01" y2="17"></line>
          </svg>
        </div>
      </div>

      <k-dialog-actions-bar>
        <!-- <k-button @click='toggleDialog(false)'>Άκυρο</k-button> -->
        <!-- <k-button :theme-color="'primary'" @click='toggleDialog(true)'>Αποστολή</k-button> -->

        <spinner-button
          ref="cancelDialogButton"
          buttonClass="w-60 px-4 text-lg font-semibold text-white transition-colors duration-300 bg-gray-500 rounded-md shadow hover:bg-gray-600 focus:outline-none focus:ring-blue-200 focus:ring-4"
          idleText="Άκυρο"
          @click="toggleDialog(false)"
        />

        <spinner-button
          ref="loginButton"
          buttonClass="w-60 px-4 text-lg font-semibold text-white transition-colors duration-300 bg-[#d1182b] rounded-md shadow hover:bg-red-600 focus:outline-none focus:ring-blue-200 focus:ring-4"
          idleText="Αποστολή"
          @click="sendRequest"
          v-bind:disabled="sendRequestButtonDisabled"
        />
      </k-dialog-actions-bar>
    </k-dialog>
    <custom-card
      title="Απεσταλμένες Προσφορές - Κριτήρια αναζήτησης"
      class="mt-2"
      extraClass="w-full"
    >
      <div>
        <div class="flex flex-row mt-8 items-center justify-center">
          <div class="flex flex-col mx-2 w-60">
            <label
              class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2"
              for="startDateInput"
              >ΑΠΟ ΗΜΕΡΟΜΗΝΙΑ</label
            >
            <k-date-picker
              id="startDateInput"
              :format="'dd/MM/yyyy'"
              :value="startDateFilter"
              @change="startDateChanged"
            >
              <span class="close-btn">XXXX</span>
            </k-date-picker>
          </div>

          <div class="flex flex-col mx-2 w-60">
            <label
              class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2"
              for="endDateInput"
              >ΕΩΣ ΗΜΕΡΟΜΗΝΙΑ</label
            >
            <k-date-picker
              id="endDateInput"
              :format="'dd/MM/yyyy'"
              :value="endDateFilter"
              @change="endDateChanged"
            >
              <!-- <span class="close-btn" @click="clear">WTFISTHISSHIT</span> -->
            </k-date-picker>
          </div>
        </div>

        <!-- <k-button class="my-8 mx-2 px-2 w-40" :theme-color="'primary'" @click="loadData">ΑΝΑΖΗΤΗΣΗ</k-button> -->
        <spinner-button
          ref="searchButton"
          buttonClass="w-40 px-2 py-1 text-white transition-colors duration-300 bg-red-500 rounded-md shadow enabled:hover:bg-red-600 focus:outline-none"
          idleText="ΑΝΑΖΗΤΗΣΗ"
          @idleClick="loadData"
        />
        <k-button
          class="my-8 mx-2 px-2 w-40"
          :theme-color="'secondary'"
          @click="clearFilters"
          >ΚΑΘΑΡΙΣΜΑ</k-button
        >
      </div>
    </custom-card>
  </div>

  <div class="flex flex-row overflow-auto m-2 p-4" style="max-width: 86vw">
    <k-grid
      class="mt-2"
      ref="grid"
      :data-items="processedGridData"
      :sortable="true"
      :sort="sort"
      :filterable="true"
      :filter="filter"
      :pageable="false"
      :skip="skip"
      :take="take"
      @sortchange="sortChangeHandler"
      @filterchange="filterChangeHandler"
      @pagechange="pageChangeHandler"
      :columns="columns"
    >
      <template v-slot:myTemplate="{ props }">
        <div style="display: table-cell; vertical-align: middle">
          <k-button
            icon="email"
            theme-color="primary"
            fill-mode="flat"
            @click="resend(props.dataItem)"
          >
            Επαναποστολή
          </k-button>
        </div>
      </template>
    </k-grid>
  </div>
</template>

<script>
import { Grid } from "@progress/kendo-vue-grid";
import {
  Button,
  SplitButton,
  DropDownButton,
} from "@progress/kendo-vue-buttons";

import CustomCard from "@/components/CustomCard.vue";

import apiClient from "@/apiClient";

import { DatePicker } from "@progress/kendo-vue-dateinputs";

import SpinnerButton from "@/components/SpinnerButton.vue";

import { Dialog, DialogActionsBar } from "@progress/kendo-vue-dialogs";
import { process } from "@progress/kendo-data-query";

export default {
  name: "TravelPolicyRequestsView",

  components: {
    "k-grid": Grid,
    "k-button": Button,
    "k-split-button": SplitButton,
    "k-dropdown-button": DropDownButton,
    "k-date-picker": DatePicker,
    "k-dialog": Dialog,
    "k-dialog-actions-bar": DialogActionsBar,
    CustomCard,
    SpinnerButton,
  },

  async mounted() {
    const token = await apiClient.login(null, null);
    this.apiToken = token;

    const today = new Date();
    today.setHours(0, 0, 0);

    this.endDateFilter = today;

    const fromDateValue = new Date();
    fromDateValue.setHours(0, 0, 0);
    fromDateValue.setDate(today.getDate() - 30);

    this.startDateFilter = fromDateValue;

    this.loadData();
    // this.loadSavedOffers(token, "01/09/2022", "26/10/2022");
  },

  data() {
    return {
      showSuccessDialog: false,
      visibleDialog: false,
      dialogEmailInput: null,
      verifiedDialogEmail: false,
      dialogInfo: {
        customerName: null,
      },

      apiToken: null,

      startDateFilter: null,
      endDateFilter: null,

      sort: [{ field: "RequestTimestampDate", dir: "desc" }],
      filter: null,
      skip: 0,
      take: 20,
      columns: [
        {
          title: "Actions",
          cell: "myTemplate",
          filterable: false,
          width: "140",
        },
        // { field: 'status', title: 'Status' },
        //{ field: 'id', editable: false, title: 'ID', width: '300px' },
        // { field: 'NNAgentCode', editable: false, title: 'NN Agent Code', hidden: true },
        { field: "QuoteId", title: "Policy ID", width: 100, filterable: false },
        {
          field: "RequestTimestampDate",
          title: "Requested At",
          filter: "date",
          type: "date",
          format: "{0:dd/MM/yyyy}",
        },
        { field: "RequestStatus", title: "Status" },
        {
          field: "PolicyGrossPriceNumber",
          title: "Gross Price",
          format: "{0:n2}",
          filter: "numeric",
          className: "kgrid-td-rightalign",
          width: "100",
          filterable: false,
        },
        { field: "CustomerName", title: "Customer Name" },
        { field: "SellOnBehalfOf", title: "Sell On Behalf Of" },
        { field: "CustomerEmail", title: "E-mail Address" },
        { field: "CustomerVatNumber", title: "VAT Number" },
        { field: "PolicyTypeName", title: "Policy" },
        { field: "Residence", title: "Residence" },
        { field: "Destination", title: "Destination" },
        { field: "TripDates", title: "Trip Dates", filterable: false },

        // { field: 'startDate', title: 'Start Date' },
        // { field: 'endDate', title: 'End Date'},
        // { field: 'policytypeName', title: 'Policy Name' },
        // { field: 'GrossPriceNum', title: 'Gross Price', format: '{0:n2}', filter: 'numeric', className: 'kgrid-td-rightalign' }
      ],
      requests: [],
    };
  },

  computed: {
    sendRequestButtonDisabled() {
      return !this.verifiedDialogEmail;
    },

    getTravelPolicyRequestsUrl() {
      return new URL(
        "/travel/get_policy_issue_requests",
        this.$appConfig.KUA_API_BASE_URL
      );
    },

    hideAgentIdField() {
      return true;
    },

    processedGridData() {
      return process(this.requests, {
        sort: this.sort,
        filter: this.filter,
        take: this.take,
        skip: this.skip,
      });
    },
  },

  methods: {
    toggleSuccessDialog() {
      this.showSuccessDialog = !this.showSuccessDialog;
    },

    isValidEmail(email) {
      return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
        email
      );
    },

    verifyDialogEmail() {
      const input = this.dialogEmailInput;

      this.verifiedDialogEmail = this.isValidEmail(input);
    },
    
    async sendRequest() {
      const policyId = parseInt(this.dialogSelectedPolicy.QuoteId);
      const response = await apiClient.requestPaymentNotification(
        this.apiToken,
        policyId,
        this.dialogEmailInput
      );

      this.toggleDialog();
      this.showSuccessDialog = true;
    },

    async toggleDialog(sendMail) {
      this.visibleDialog = !this.visibleDialog;

      // if (sendMail == false) {
      //     // console.log('mail aborted')
      // } else {
      //     // console.log('sending mail')

      // }
    },

    resend(args) {
      this.visibleDialog = true;
      this.dialogSelectedPolicy = args;
      this.dialogEmailInput = args.CustomerEmail;
      this.verifiedDialogEmail = this.isValidEmail(this.dialogEmailInput);
      
    },

    loadData() {
      const dateOptions = { month: "2-digit", day: "2-digit", year: "numeric" };
      const fromDate = this.startDateFilter?.toLocaleDateString(
        "el-GR",
        dateOptions
      );
      const toDate = this.endDateFilter?.toLocaleDateString(
        "el-GR",
        dateOptions
      );

      // const nnAgentCode =
      //  this.$store.state.userAgentCode ?? this.$store.state.user;

      this.loadIssueRequests(this.apiToken, fromDate, toDate);
    },

    clearFilters() {
      this.startDateFilter = null;
      this.endDateFilter = null;
    },

    startDateChanged(event) {
      this.startDateFilter = event.value;
    },

    endDateChanged(event) {
      this.endDateFilter = event.value;
    },

    async loadIssueRequests(token, from, to) {
      const url = this.getTravelPolicyRequestsUrl.href;

      const requestBody = {
        token: token,
        minDate: from,
        maxDate: to,
      };

      const response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      const requests = await response.json();

      this.requests = requests.map((request) => ({
        ...request,
        RequestTimestampDate: new Date(request.RequestTimestamp),
        PolicyGrossPriceNumber: Number(request.PolicyGrossPrice),
        TripDates: `${request.PolicyStartDate} - ${request.PolicyEndDate}`,
      }));
    },

    sortChangeHandler(event) {
      this.sort = event.sort;
    },

    filterChangeHandler(event) {
      this.filter = event.filter;
    },

    pageChangeHandler(event) {
      this.take = event.page.take;
      this.skip = event.page.skip;
    },
  },
  inject: ["$appConfig"],
};
</script>
