<template>
    <div>
        <k-form-element :horizontal="true">
        <fieldset class="k-form-fieldset">
            <div class="flex flex-col content-center items-center justify-center">
                <div>
                    <span class="font-semibold">Additional Coverages for Policy</span>
                    <!-- 1st division -->

                    <table>
                        <tbody>
                            <tr v-for="question in forAll" v-bind:key="question.coverage_id">
                                <td style="width: 200px">
                                    {{question.DisplayName}}
                                </td>
                                <td style="width: 200px">
                                    <k-form-field v-if="question.CoverType=='Boolean'"
                                        :name="createBooleanName(question)"
                                        :component="'myTemplate'"
                                        :horizontal="true"
                                    >
                                        <template v-slot:myTemplate="{props}">
                                            <k-form-checkbox
                                                v-bind="props"
                                                @change="props.onChange"
                                                @blur="props.onBlur"
                                                @focus="props.onFocus"
                                            />
                                        </template>
                                    </k-form-field> 
                                    <k-form-field v-else
                                        :name="createOptionName(question.DisplayName)"
                                        :component="'myTemplate'"
                                        :horizontal="true"
                                        :data-items="question.ValidValues"
                                        :default-item="{Description: 'None', coverage_id: 0}"
                                        :text-field="'Description'"
                                        :data-item-key="'coverage_id'"
                                    >
                                        <template v-slot:myTemplate="{props}">
                                            <k-dropdown-list
                                                v-bind="props"
                                                @change="props.onChange"
                                                @blur="props.onBlur"
                                                @focus="props.onFocus"
                                            />
                                        </template>
                                    </k-form-field>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div>
                    <span class="font-semibold">Additional Coverages for traveller</span>
                   
                    <table>
                        <tbody>
                            <k-form-field
                                        :name="'traveller'"
                                        :component="'myTemplate'"
                                        :horizontal="true"
                                        :data-items="persons" 
                                        class="w-40"
                                    >
                                        <template v-slot:myTemplate="{props}">
                                            <k-dropdown-list
                                                v-bind="props"
                                                @change="personChange"
                                                @blur="props.onBlur"
                                                @focus="props.onFocus"
                                            />
                                        </template>
                                    </k-form-field> 
                            <!-- <div v-for="(question,index) in selectedSubItems" v-bind:key="index"> -->
                                <tr v-for="cover in selectedSubItems" v-bind:key="cover.cover">
                                    <td style="width: 200px">
                                        {{cover.displayName}}
                                    </td>
                                    <td style="width: 200px">
                                        <k-form-field v-if="cover.CoverType=='Boolean'"
                                            :name="createTravellerBooleanName(cover)"
                                            :component="'myTemplate'"
                                            :horizontal="true"
                                        >
                                            <template v-slot:myTemplate="{props}">
                                                <k-form-checkbox
                                                    v-bind="props" 
                                                    @blur="props.onBlur"
                                                    @focus="props.onFocus" 
                                                    :key="cover.cover"
                                                    :value="cover.isChecked"
                                                    :indeterminate="kendoForm.values.traveller === 'Apply to All' ? cover.isIndeterminate : false"
                                                    @change="handleCoverChange(cover.cover, $event)"
                                                />
                                            </template>
                                        </k-form-field>  
                                        <k-form-field v-else
                                            :name="createTravellerOptionName(cover.displayName)"
                                            :component="'myTemplate'"
                                            :horizontal="true"
                                        >
                                            <template v-slot:myTemplate="{props}">
                                                <k-form-checkbox
                                                    v-bind="props" 
                                                    @blur="props.onBlur"
                                                    @focus="props.onFocus" 
                                                    :key="cover.cover"
                                                    :value="cover.isChecked"
                                                    :indeterminate="kendoForm.values.traveller === 'Apply to All' ? cover.isIndeterminate : false"
                                                    @change="handleCoverChange(cover.cover, $event)"
                                                />
                                            </template>
                                        </k-form-field> 
                                    </td>
                                </tr>
                            <!-- </div> -->
                        </tbody>
                    </table>
                </div>
            </div>
        </fieldset>
        <!-- {{ kendoForm.values }} -->
    </k-form-element>
    </div>
</template>

<script>

import FormCheckbox from "./FormCheckbox.vue";
import { Field, FormElement  } from "@progress/kendo-vue-form";
import { ref } from 'vue'

import apiClient from "@/apiClient";
import CustomRadioGroup from "./CustomRadioGroup.vue"

import slugify from 'slugify'
import dummyExtras from "./dummyExtras";

import { DropDownList } from "@progress/kendo-vue-dropdowns";

export default {
    name: 'StepThree',

    components: {
        'k-form-element' : FormElement,
        'k-form-field' : Field,
        'k-dropdown-list' : DropDownList,
        'k-form-checkbox' : FormCheckbox,
        CustomRadioGroup
    },

    inject: {
        kendoForm: { default: {} },  
    },

    data: function() {
        return {
            questions: [],
            perTraveller:[],
            forAll:[],
            persons:[],
            personEntities:[],
            sum:0,
            entities:[],
            isChecked:false,
            covers:[],
            personEntity:{},
            coverObj:{},
            coverArr:[],
            finalArrPersons:[]

        }
    },

    computed: {
        // selectedSubItems() {
        //     const entity = this.finalArrPersons.find((item)=>{
        //         return item.person===this.kendoForm.values.traveller
        //     });
        //     if(entity!=undefined){
        //         return entity.covers;
        //     }else{
        //         return false;
        //     }

        // },
        selectedSubItems() {
    if (this.kendoForm.values.traveller === 'Apply to All') {
      return this.perTraveller.map((baseCover) => {
        const isCheckedAcrossAll = this.finalArrPersons.every((person) => {
          const cover = person.covers.find((c) => c.cover === baseCover.coverage_id);
          return cover?.isChecked === true;
        });

        const isCheckedBySome = this.finalArrPersons.some((person) => {
          const cover = person.covers.find((c) => c.cover === baseCover.coverage_id);
          return cover?.isChecked === true;
        });

        return {
          displayName: baseCover.DisplayName,
          cover: baseCover.coverage_id,
          isChecked: isCheckedAcrossAll,
          isIndeterminate: !isCheckedAcrossAll && isCheckedBySome,
          CoverType: baseCover.CoverType
        };
      });
    } else {
      const person = this.finalArrPersons.find(
        (p) => p.person === this.kendoForm.values.traveller
      );
      return person?.covers || [];
    }
  },    
        // persons() {
        //         return this.finalArrPersons.map(item => ({
        //             name: item.person,
        //             covers: item.covers
        //         }));
        // },
        booleanQuestions() {
            return this.questions.filter(x => x.CoverType == "Boolean")
        },

        selectQuestions() {
            let questions = this.questions.filter(x => x.CoverType == "Selection").map(function (item) {
                return {
                    ...item,
                    radioButtonValues: item.ValidValues.map(
                        function (value) {
                            return { label : value.Description, value : value.coverage_id }
                        }
                    )
                }
            })

            questions.forEach(function (item) {
                item.radioButtonValues.push({label: 'None', value: 0});
            })

            return questions
            
        },
        filteredCheckboxes() {
            return this.checkboxes.filter(
                (checkbox) => checkbox.category === this.selectedCategory
            );
        },

        // isStepValid: () => true
    },

    props: [
        'activeStepNumber',
        'myStepNumber',
        'lastChangedStepNumber'
    ],


    watch: {
        activeStepNumber: function(val) {
            if (val == this.myStepNumber) {
                this.stepActivated()
                this.onChange()
            }
        }
    },

    mounted() {
        
        // this.questions = dummyExtras;
    },

methods: {
    handleCoverChange(coverId, event) {
            const isChecked = event.value;

            if (this.kendoForm.values.traveller === 'Apply to All') {
                this.finalArrPersons.forEach((person) => {
                const cover = person.covers.find((c) => c.cover === coverId);
                if (cover) cover.isChecked = isChecked;
                });
            } else {
                const person = this.finalArrPersons.find(
                (p) => p.person === this.kendoForm.values.traveller
                );
                const cover = person?.covers.find((c) => c.cover === coverId);
                if (cover) cover.isChecked = isChecked;
            }
    },
    getCoversForTraveller() {
        if (this.kendoForm.values.traveller === 'Apply to All') {
            return this.perTraveller.map((baseCover) => {
            const coverId = baseCover.coverage_id;

            const isCheckedAcrossAll = this.finalArrPersons.every((person) => {
                const cover = person.covers.find((c) => c.cover === coverId);
                return cover?.isChecked === true;
            });

            const isCheckedBySome = this.finalArrPersons.some((person) => {
                const cover = person.covers.find((c) => c.cover === coverId);
                return cover?.isChecked === true;
            });

            return {
                displayName: baseCover.DisplayName,
                cover: coverId,
                isChecked: isCheckedAcrossAll,
                isIndeterminate: !isCheckedAcrossAll && isCheckedBySome,
                CoverType: baseCover.CoverType
            };
            });
        } else {
            const person = this.finalArrPersons.find(
            (p) => p.person === this.kendoForm.values.traveller
            );
            return person?.covers || [];
        }
    },

        async getAdditionalCoverages() {
            // console.log(this.kendoForm.values)
            const additionalCoverages = await apiClient.getAdditionalCoverages(
                this.kendoForm.values.apiToken,
                "ASUA",
                this.kendoForm.values.travelStartDate,
                this.kendoForm.values.travelEndDate,
                this.kendoForm.values.residenceCountry.label,
                this.kendoForm.values.destination.value,
                this.kendoForm.values.travelCategory,
                this.kendoForm.values.travelType,
                // this.kendoForm.values.sobo.value,
              //v1.0.1.5 Προσθήκη insuredCategory value στην function getAdditionalCoverages()
                this.kendoForm.values.insuredCategory,
              //v1.0.1.5 Προσθήκη insuredCategory value στην function getAdditionalCoverages()
            )
            this.questions = additionalCoverages
            
            this.questions.forEach((cover)=>{
                if(cover.Position=='Per Traveller'){
                    this.perTraveller.push(cover);
                    
                }else{
                    this.forAll.push(cover)
                }
            });
           
            // this.coverArr = this.perTraveller.map(item=>({
            //             displayName:item.DisplayName,
            //             cover:item.coverage_id,
            //             isChecked:false,
            //             CoverType:item.CoverType
            //         }));
            
            
            // this.finalArrPersons = this.persons.map(person => ({
            //     person: person,
            //     covers: this.coverArr
            // }));
            this.finalArrPersons=[];
            this.finalArrPersons = this.persons.map(person => ({
                    person,
                    covers: this.perTraveller.map(item => ({
                        displayName: item.DisplayName,
                        cover: item.coverage_id,
                        isChecked: false,
                        CoverType: item.CoverType
                    }))
                    }));

                    // console.log(this.perTraveller);
                    // console.log(this.finalArrPersons);
        },
        isCoverChecked(coverId) {
            if (this.kendoForm.values.traveller !== 'Apply to All') {
                const person = this.finalArrPersons.find(
                (p) => p.person === this.kendoForm.values.traveller
                );
                const cover = person?.covers.find((c) => c.cover === coverId);
                return cover?.isChecked || false;
            } else {
                // For "Apply to All": return true ONLY if all persons have this cover checked
                return this.finalArrPersons.every((person) => {
                const cover = person.covers.find((c) => c.cover === coverId);
                return cover?.isChecked;
                });
            }
            },

        isStepValid() {
            // console.log(this.myStepNumber);
        },

        stepActivated() { 
            if (this.lastChangedStepNumber <= this.myStepNumber) {
                this.stepReset()
            } 
            // console.log(this.kendoForm.values)
            const allAgeBands = Object.keys(this.kendoForm.values)
            .filter((x) => x.startsWith("age_band"))
            .map((x) => ({
            ageBandId: x.split(".")[1],
            travellerCount: parseInt(this.kendoForm.values[x]),
            ageBandDescription: x.split(".")[2], 
            }));
            
            const activeAgeBands = allAgeBands.filter((x) => x.travellerCount > 0);
 
            let travellerIndex = 1;

            activeAgeBands.forEach((x) => {
                for (let i = 0; i < x.travellerCount; i++) {
                    const label = `Traveller ${travellerIndex} - (${x.ageBandDescription})`;
                    this.persons.push(label);
                    travellerIndex++;
                }
            }); 
            // console.log(this.persons)
            if (this.questions.length == 0){
                this.getAdditionalCoverages()
            } 
        },

        stepReset() {
            this.questions = []
            this.perTraveller=[]
            this.forAll=[]
            this.personEntities=[]
            this.persons=[]
            this.finalArrPersons=[]
            this.sum = 0
            const fieldNames = Object.keys(this.kendoForm.values).filter((x) => x.startsWith("extra_coverage_"))

            fieldNames.forEach(name => {
                delete this.kendoForm.values[name];
            })

        },
        
        personChange(event){
            const entity = this.finalArrPersons.find((item)=>{
                return item.person===this.kendoForm.values.traveller
            });
            
        },
        remEt(a, ele) {
            a.forEach((item, index) => {
                if (item === ele) {
                    a.splice(index, 1);
                }
            });
            return a;
        },

        onAddSelection(event){
            // let keys = Object.keys(this.kendoForm.values).filter(x=>x.startsWith("extra_coverage_traveller"))
            
            // // let personame = this.personEntities.find((item) => {
            // //     const personKey = Object.keys(item)[0];
            // //     if(personKey===this.kendoForm.values.traveller){
            // //         return personKey;
            // //     }
            // // });
            // let personame = this.finalArrPersons.find((item)=>{
            //     return item.person===this.kendoForm.values.traveller
            // })
            // // console.log(personame.person);
            // console.log(this.finalArrPersons);
            // console.log("1");
            // const allPersonCovers = Object.keys(this.kendoForm.values)
            // .filter((x) => x.startsWith("extra_coverage_traveller"))
            // .map((x) => ({
            // person: x.split(".")[2]
            // }));
            const allIdCovers = Object.keys(this.kendoForm.values)
            .filter((x) => x.startsWith("extra_coverage_traveller"))
            .map((x) => ({
            id: x.split(".")[1]
            }));
           // let personstr = ""
            // let personVal = Object.values(allPersonCovers)
            // personVal.forEach((p)=>{
            //     personstr=p.person
            // })
            let coverstr = ""
            let coverId = Object.values(allIdCovers)
            coverId.forEach((p)=>{
                coverstr=p.id
            })
            

            //let personString=Object.keys(personame)[0];
            // if(this.kendoForm.values.traveller!='Apply to All'){
                    // if(personame.person == this.kendoForm.values.traveller){
            // personame.covers.forEach(cover=>{
            //     if(cover.cover == coverstr && personame.person == this.kendoForm.values.traveller){
            //         console.log(personame);
            //         cover.isChecked=event.value;
            //     }
            // })
                    // }else{
                    //     console.log("signwmi kurie poios eiste?");
                    // }
            //    console.log(this.coverArr);
            // }else{
            //     this.personEntities.forEach(element => {
            //         if(this.kendoForm.values[key]==true){
            //             if(element.coverages.includes(key)==false){
            //                 element.coverages.push(key);
            //             }
            //         }else{
            //             this.remEt(element.coverages,key);
            //         }
            //     })
            // }
  
            if (this.kendoForm.values.traveller !== 'Apply to All') {
                // Apply to selected person only
                const personObj = this.finalArrPersons.find((item) => {
                    return item.person === this.kendoForm.values.traveller;
                });

                if (personObj) {
                    const coverObj = personObj.covers.find((cover1) => {
                    return cover1.cover == coverstr;
                    });

                    if (coverObj) {
                    coverObj.isChecked = event.value;
                    }
                }
                } else {
                // Apply to ALL persons
                this.finalArrPersons.forEach((person) => {
                    const coverObj = person.covers.find((cover1) => {
                    return cover1.cover == coverstr;
                    });

                    if (coverObj) {
                    coverObj.isChecked = event.value;
                    }
                });
                }

                // console.log(this.finalArrPersons);

        } ,
        
        onChange(event,stepActuallyChanged = true) {
            const validationState = this.isStepValid();
            this.$emit('onChange', { stepNumber: this.myStepNumber, stepValid: this.isStepValid, stepActuallyChanged: stepActuallyChanged });
        },

        createOptionName(text) {
            return 'extra_coverage_selection.' + slugify(text, {
                lower : true,
                strict : true
            })
        },

        createBooleanName(question) {
            return `extra_coverage_boolean.${question.coverage_id}.` + slugify(question.DisplayName, {
                lower : true,
                strict : true
            })
        },
        createTravellerOptionName(text) {
            return `extra_coverage_traveller_selection.${this.kendoForm.values.traveller}.` + slugify(text, {
                lower : true,
                strict : true
            })
        },

        createTravellerBooleanName(question) {
            return `extra_coverage_traveller_boolean.${question.cover}.${this.kendoForm.values.traveller}.` + slugify(question.displayName, {
                lower : true,
                strict : true
            })
        }


    }
}
</script>