<template>
  <div
    class="bg-auto bg-no-repeat bg-center justify-center w-full"
    style="background-image: url('kuaoffice.jpg')"
  >
    <div class="flex items-center h-screen p-4 justify-center">
      <div
        class="h-[400px] flex flex-col overflow-hidden bg-white rounded-md shadow-lg max md:flex-row md:flex-1 lg:max-w-screen-md"
      >
        <div
          class="px-4 pb-6 text-white bg-gray-200 md:w-80 md:flex-shrink-0 md:flex md:flex-col md:items-center md:justify-evenly"
        >
          <div class="mb-3 text-4xl font-bold tracking-wider text-center">
            <div><img src="kualogo.png" /></div>
          </div>
          <div class="bg-[#d1182b]">
            <p class="mt-6 font-normal text-left text-white md:mt-0 p-2">
              Για οποιαδήποτε διευκρίνηση σχετικά με την χρήση της εφαρμογής,
              παρακαλώ επικοινωνήστε μαζί μας στο
              <a href="mailto:it-support@gkaravias.gr"
                >it-support@gkaravias.gr</a
              >
              ή στο 210 36 40 618.
            </p>
            <!-- <p class="mt-6 text-sm text-center text-white">
            Read our <a href="#" class="underline">terms</a> and <a href="#" class="underline">conditions</a>
            </p> -->
          </div>
        </div>
        <div class="p-5 bg-gray-100 md:flex-1">
          <h3 class="mt-8 mb-4 text-2xl font-semibold text-gray-700 px-24">
            KUA Portal Login
          </h3>
          <!-- <h3 class="mt-8 mb-4 text-xl font-semibold text-gray-700 px-24">Επαναφορά κωδικού</h3> -->

          <!-- email / password prompt -->

          <div class="flex flex-col space-y-5">
            <div class="flex flex-col space-y-1">
              <div class="flex items-center bg-white rounded shadow-md mb-4">
                <!-- <span class="px-3">
                <svg class="fill-current text-gray-600 w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zM7 6v2a3 3 0 1 0 6 0V6a3 3 0 1 0-6 0zm-3.65 8.44a8 8 0 0 0 13.3 0 15.94 15.94 0 0 0-13.3 0z"/></svg>
                </span> -->
                <input
                  class="px-3 w-full h-12 focus:outline-none"
                  type="text"
                  placeholder="Username"
                  v-model="username"
                />
              </div>
            </div>
            <div class="flex flex-col space-y-1">
              <div class="flex items-center bg-white rounded shadow-md mb-4">
                <!-- <span class="px-3">
                <svg class="fill-current text-gray-500 w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M18 2a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4c0-1.1.9-2 2-2h16zm-4.37 9.1L20 16v-2l-5.12-3.9L20 6V4l-10 8L0 4v2l5.12 4.1L0 14v2l6.37-4.9L10 14l3.63-2.9z"/></svg>
                </span> -->
                <input
                  class="px-3 w-full h-12 focus:outline-none"
                  type="text"
                  placeholder="Email"
                  v-model="email"
                />
              </div>
            </div>
            <div>
              <spinner-button
                ref="requestResetButton"
                buttonClass="w-60 px-4 py-2 text-lg font-semibold text-white transition-colors duration-300 bg-[#d1182b] rounded-md shadow hover:bg-red-600 focus:outline-none focus:ring-blue-200 focus:ring-4"
                idleText="Επαναφορά κωδικού"
                @click="requestReset"
              />
            </div>
            <router-link
              to="/login"
              class="text-sm text-red-600 hover:underline focus:text-red-800"
              >Επιστροφή</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SpinnerButton from "@/components/SpinnerButton.vue";

export default {
  name: "ForgotPasswordView.vue",
  components: {
    SpinnerButton,
  },

  inject: ["$appConfig"],

  data() {
    return {
      username: null,
      email: null,
    };
  },

  methods: {
    async requestReset() {
      const url = this.requestPasswordResetUrl;

      const body = {
        username: this.username,
        email: this.email,
      };

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      };

      try {
        const response = await fetch(url, requestOptions);
        this.$refs.requestResetButton.setStateIdle();
        //const data = await response.json();
        if (response.status === 200) {
          // console.log(response.data)
          this.$swal.fire(
            "Εντάξει",
            "Ελέγξτε τα εισερχόμενα σας για το email με τον σύνδεσμο επαναφοράς κωδικού πρόσβασης",
            "success"
          );
          this.$refs.requestResetButton.setStateFinished();
        } else {
          this.$swal.fire("Προσοχή", "Τα στοιχεία δεν είναι σωστά", "error");
        }
      } catch (error) {
        this.$swal.fire("Προσοχή", "Τα στοιχεία δεν είναι σωστά", "error");
        this.$refs.requestResetButton.setStateIdle();
        // console.log(error);
      }
    },
  },

  computed: {
    requestPasswordResetUrl() {
      return new URL(
        this.$appConfig.API_REQUEST_PASSWORD_RESET_URL,
        this.$appConfig.API_BASE_URL
      );
    },
  },
};
</script>
