async function post(url, body) {
  const response = await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });

  return response;
}

// Version 1.0.0.1  Date: 10/04/2023: add insuredCategory in global function getTravelDetails()
async function getTravelDetails(
  token,
  travelCategory,
  travelType,
  insuredCategory
) {
  const targetUrl = "https://api.gkaravias.gr/travel/traveltypedetails";
  const body = {
    token: token,
    // travel_category: travelCategory,
    // travel_type: travelType,
    travel_category: travelType,
    travel_type: travelCategory,
    insured_category: insuredCategory,
  };

  const response = await post(targetUrl, body);
  const content = await response.json();

  return content;
}
async function getSoboDetails(token,company) {
  const targetUrl = "https://api.gkaravias.gr/travel/get_sell_on_behalf_of";
  const body = {
    token: token,
    company:company
  };

  const response = await post(targetUrl, body);
  const content = await response.json();

  return content;
}

// Version 1.0.0.1  Date: 10/04/2023: add insuredCategory in global function getAdditionalCoverages()
async function getAdditionalCoverages(
  token,
  platform,
  endDate,
  startDate,
  residence,
  destination,
  travelCategory,
  travelType,
  insuredCategory
) {
  const targetUrl = "https://api.gkaravias.gr/travel/additional_coverages";

  const body = {
    token: token,
    // travel_category: travelCategory,
    // travel_type: travelType,
    travel_category: travelType,
    travel_type: travelCategory,
    platform: platform,
    end_date: endDate,
    start_date: startDate,
    residence: residence,
    destination: destination,
    insured_category: insuredCategory,
  };

  const response = await post(targetUrl, body);
  const content = await response.json();

  return content;
}

async function getQuotes(payload) {
  const targetUrl = "https://api.gkaravias.gr/travel/pricing";

  const response = await post(targetUrl, payload);
  const content = await response.json();

  return content;
}

async function saveTravelOffer(token, nnAgentCode, quoteId, offerName,company) {
  const targetUrl = "https://api.gkaravias.gr/travel/save_offer";

  const payload = {
    NN_Agent_Code: nnAgentCode,
    offername: offerName,
    platform: "ASUA",
    quoteIDs: quoteId,
    token: token,
    company:company
  };

  const response = await post(targetUrl, payload);
  const content = await response.json();

  return content;
}
async function FRB_saveOffers(username, password, quoteId, offerName) {
  const targetUrl = "https://api.gkaravias.gr/frb/frb_saved_offer";

  const payload = {
    username: username ?? "elipatr",
    password: password ?? "111111",
    InitialQuoteID: quoteId,
    offername: offerName,
  };

  const response = await post(targetUrl, payload);
  const content = await response.json();

  return content;
}

async function getTravelSavedOffersMetadata(token) {
  const targetUrl = "https://api.gkaravias.gr/reporting/get_report_metadata";

  const payload = {
    token: token,
  };

  const response = await post(targetUrl, payload);
  const content = await response.json();

  return content;
}

async function requestPaymentNotification(token, policyId, emailAddress) {
  const targetUrl =
    "https://api.gkaravias.gr/travel/request_payment_notification";

  const payload = {
    token: token,
    policyId: policyId,
    mailAddress: emailAddress,
  };

  const response = await post(targetUrl, payload);
  const content = await response.json();

  return content;
}

async function getTravelSavedOffers(token, fromDate, toDate) {
  const metadata = await getTravelSavedOffersMetadata(token);

  // console.log(metadata)

  const classOfBusiness = metadata.ClassOfBusiness.shift();
  const insuranceFirm = metadata.InsuranceFirmsResult.shift();
  const member = metadata.members.find((x) => x.username == "elipatr");

  const targetUrl = "https://api.gkaravias.gr/reporting/create_report";

  const payload = {
    token: token,
    report_type: "saved_offers",
    date_from: fromDate,
    date_to: toDate,
    // NN_Agent_code: nnAgentCode,
    class_of_business: [
      {
        CoB_code: classOfBusiness.CoB_code,
      },
    ],
    insurance_firm: [
      {
        firmID: String(insuranceFirm.firmID),
      },
    ],
    Partner_memberID: [
      {
        memberID: String(member.memberID),
      },
    ],
  };

  const response = await post(targetUrl, payload);
  const content = await response.json();

  return content;
}
async function getFrbSavedOffers(username, password, fromDate, toDate) {
  // const metadata = await getTravelSavedOffersMetadata(token);

  // // console.log(metadata)

  // const classOfBusiness = metadata.ClassOfBusiness.shift();
  // const insuranceFirm = metadata.InsuranceFirmsResult.shift();
  // const member = metadata.members.find((x) => x.username == "elipatr");

  const targetUrl = "https://api.gkaravias.gr/frb/get_report";

  const payload = {
    username: username ?? "elipatr",
    password: password ?? "111111",
    report_type: "saved_offers",
    date_from: fromDate,
    date_to: toDate,
    // NN_Agent_code: nnAgentCode,
  };

  const response = await post(targetUrl, payload);
  const content = await response.json();

  return content;
}

async function getScreeningQuestions(token, quoteId, platform) {
  const targetUrl =
    "https://api.gkaravias.gr/travel/get_screening_questions";

  const payload = {
    token,
    quoteID: quoteId,
    platform,
  };

  const response = await post(targetUrl, payload);
  const content = await response.json();

  return content;
}

async function login(username, password) {
  const targetUrl = "https://api.gkaravias.gr/user/login";

  const payload = {
    username: username ?? "elipatr",
    password: password ?? "111111",
  };

  const response = await post(targetUrl, payload);
  const content = await response.json();

  return content.HashedToken;
}

async function updatePolicyDetails(
  token,
  quoteID,
  travellers,
  PolicyholderDetails,
  PolicyNotes
) {
  const targetUrl = "https://api.gkaravias.gr/travel/update_policy_details";

  const payload = {
    token,
    quoteID,
    travellers,
    PolicyholderDetails,
    PolicyNotes: PolicyNotes ?? [],
  };

  const response = await post(targetUrl, payload);
  const content = await response.json();

  return content;
}

async function FRB_GetZipCodes(username, password) {
  const targetUrl = "https://api.gkaravias.gr/frb/get_zipcodes";

  const payload = {
    // username,
    // password

    username: username ?? "elipatr",
    password: password ?? "111111",
  };

  const response = await post(targetUrl, payload);
  const content = await response.json();

  return content;
}

async function FRB_Get_Quote(username, password, payloadforcall) {
  const targetUrl = "https://api.gkaravias.gr/frb/get_initial_quote";

  const payload = {
    username: username ?? "elipatr",
    password: password ?? "111111",
    usage: payloadforcall.usage,
    constructionYear: payloadforcall.constructionYear,
    postalCode: payloadforcall.postalCode,
    squareFootage: payloadforcall.squareFootage,
    constructionQuality: payloadforcall.constructionQuality,
    roofType: payloadforcall.roofType,
    hasSecurityMeasures: payloadforcall.hasSecurityMeasures,
    isMortgage: payloadforcall.isMortgage,
    floor: payloadforcall.floor,
    packageCategory: payloadforcall.packageCategory,
  };

  const response = await post(targetUrl, payload);
  const content = await response.json();

  return content;
}

async function FRB_Get_Description(username, password, payloadforcall) {
  const targetUrl = "https://api.gkaravias.gr/frb/get_fullDescription";

  const payload = {
    username: username ?? "elipatr",
    password: password ?? "111111",
    coverage_name: payloadforcall.coverage_name,
  };

  const response = await post(targetUrl, payload);
  const content = await response.json();

  return content;
}
async function FRB_Get_Contract(username, password, payloadforcall) {
  const targetUrl = "https://api.gkaravias.gr/frb/finalize_quote";

  const payload = {
    username: username ?? "elipatr",
    password: password ?? "111111",
    InitialQuoteID: payloadforcall.quote_id,
    name: payloadforcall.name,
    surname: payloadforcall.surname,
    fathersName: payloadforcall.fathersName,
    vat_number: payloadforcall.vat_number,
    email: payloadforcall.email,
    phone: payloadforcall.phone,
    street: payloadforcall.street,
    street_number: payloadforcall.street_number,
    issue_date: payloadforcall.issue_date,
    bank: payloadforcall.bank,
  };
  const response = await post(targetUrl, payload);
  const content = await response.json();

  return content;
}
async function FRB_get_requested_pdf(username, password, payloadforcall) {
  const targetUrl = "https://api.gkaravias.gr/frb/get_contract";

  const payload = {
    username: username ?? "elipatr",
    password: password ?? "111111",
    contract_id: payloadforcall.contract_id,
  };
  const response = await post(targetUrl, payload);
  const content = await response.json();

  return content;
}

async function FRB_get_report(username, password, payloadforcall) {
  const targetUrl = "https://api.gkaravias.gr/frb/get_requested_pdf";

  const payload = {
    username: username ?? "elipatr",
    password: password ?? "111111",
    date_from: payloadforcall.date_from,
    date_to: payloadforcall.date_to,
  };
  const response = await post(targetUrl, payload);
  const content = await response.json();

  return content;
}
async function FRB_is_paid(username, password, payloadforcall) {
  const targetUrl =
    "https://api.gkaravias.gr/frb/mark_finalized_quote_as_paid";

  const payload = {
    username: username ?? "elipatr",
    password: password ?? "111111",
    finalized_quoteID: payloadforcall.finalized_quoteID,
  };
  const response = await post(targetUrl, payload);
  const content = await response.json();

  return content;
}
export default {
  post,
  getTravelDetails,
  getSoboDetails,
  getAdditionalCoverages,
  getQuotes,
  saveTravelOffer,
  getTravelSavedOffers,
  requestPaymentNotification,
  getScreeningQuestions,
  updatePolicyDetails,
  login,
  FRB_GetZipCodes,
  FRB_Get_Quote,
  FRB_Get_Description,
  FRB_Get_Contract,
  FRB_get_requested_pdf,
  FRB_get_report,
  FRB_is_paid,
  FRB_saveOffers,
  getFrbSavedOffers,
};
