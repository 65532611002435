<template>
  <div :style="{
      backgroundColor: isIke ? 'rgb(241,238,250)' : 'rgb(229,231,235)',
    }" class="asd mx-2 my-8 shadow-md" v-bind:class="extraClass">
    <!-- //v1.0.3.4  Αλλαγή χρώματος γραμμής -->
    <div>
      <!-- //v1.0.3.4  Αλλαγή χρώματος γραμμής -->

      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: ["extraClass"],
  inject: ["$appConfig"],
  data() {
    return {
      isIke:false,
    };
  },
  mounted() {
    if(this.$appConfig.APP_COMPANY_NAME=="ΚΑΡΑΒΙΑΣ ΑΣΦΑΛΙΣΤΙΚΟΙ ΠΡΑΚΤΟΡΕΣ ΙΚΕ"){
      // console.log("eimai ike")
      this.isIke=true
    }else{
      // console.log("eimai AE")
    }
  },
};

</script>
