<template>
  <!--------------------//v1.0.0.1 Μείωση του width της Νέας Προσφοράς Ταξιδιωτικής ασφάλισης ----------------------->
  <div class="flex flex-col">
    <div class="flex flex-row p-4">
      <k-form
        ref="form"
        class="form-element"
        :initial-values="initialValues"
        :horizontal="true"
      >
        <custom-card
          title="Νέα προσφορά ταξιδιωτικής ασφάλισης"
          extraClass="w-3/4 h-full"
        >
          <div>
            <!--------------------//v1.0.0.1 Μείωση του width της Νέας Προσφοράς Ταξιδιωτικής ασφάλισης ----------------------->
            <k-stepper
              :class="'my-8'"
              :value="step"
              :items="steps"
              @change="stepperChange"
            />

            <step-zero
              v-show="false"
              @onChange="stepContentChanged"
              :activeStepNumber="step"
              :myStepNumber="0"
              :lastChangedStepNumber="lastChangedStep"
            />
            <step-one
              v-show="step === 0"
              @onChange="stepContentChanged"
              :activeStepNumber="step"
              :myStepNumber="0"
              :lastChangedStepNumber="lastChangedStep"
            />
            <step-two
              v-show="step === 1"
              @onChange="stepContentChanged"
              :activeStepNumber="step"
              :myStepNumber="1"
              :lastChangedStepNumber="lastChangedStep"
            />
            <step-three
              v-show="step === 2"
              @onChange="stepContentChanged"
              :activeStepNumber="step"
              :myStepNumber="2"
              :lastChangedStepNumber="lastChangedStep"
            />
            <step-four
              v-show="step === 3"
              @onChange="stepContentChanged"
              :activeStepNumber="step"
              :myStepNumber="3"
              :lastChangedStepNumber="lastChangedStep"
            />

            <span class="k-form-separator mt-8"></span>
            <div
              class="text-center content-center justify-center k-form-buttons"
            >
              <span>
                <k-button
                  v-if="step > 0"
                  class="w-20 mx-4"
                  @click="prevButtonClick"
                >
                  Πίσω
                </k-button>
                <k-button
                  class="w-20 mx-4"
                  :theme-color="'primary'"
                  @click="nextSubmitButtonClick"
                  :disabled="disableNextStep"
                  v-if="isLastStep == false"
                >
                  {{ isLastStep ? "Αποστολή" : "Επόμενο" }}
                </k-button>
              </span>
            </div>
            <!--------------------//v1.0.0.1 Μείωση του width της Νέας Προσφοράς Ταξιδιωτικής ασφάλισης ----------------------->
          </div>
        </custom-card>
        <quote-summary />
      </k-form>
    </div>
  </div>
  <!--------------------//v1.0.0.1 Μείωση του width της Νέας Προσφοράς Ταξιδιωτικής ασφάλισης ----------------------->
</template>

<script>
import { Button } from "@progress/kendo-vue-buttons";
import { Stepper } from "@progress/kendo-vue-layout";
import { Form } from "@progress/kendo-vue-form";

import StepZero from "@/components/travel/StepZero.vue";
import StepOne from "@/components/travel/StepOne.vue";
import StepTwo from "@/components/travel/StepTwo.vue";
import StepThree from "@/components/travel/StepThree.vue";
import StepFour from "@/components/travel/StepFour.vue";

import NewQuoteSummary from "./NewQuoteRequest/NewQuoteSummary.vue";

import apiClient from "@/apiClient";
import CustomCard from "../CustomCard.vue";
import { mapActions } from "vuex";
export default {
  components: {
    "k-button": Button,
    "k-stepper": Stepper,
    "k-form": Form,

    CustomCard,

    StepZero,
    StepOne,
    StepTwo,
    StepThree,
    StepFour,
    "quote-summary": NewQuoteSummary,
  },

  data: function () {
    return {
      step: 0,

      lastChangedStep: null,

      initialValues: {
        apiToken: null,
      },

      disableNextStep: false,
    };
  },

  computed: {
    steps() {
      return [
        // {
        //   label: 'API Token',
        //   componentName: 'StepZero',
        // },
        {
          label: "Basics",
          componentName: "StepOne",
        },
        {
          label: "Trip details",
          componentName: "StepTwo",
        },
        {
          label: "Extras",
          componentName: "StepThree",
        },
        {
          label: "Quotes",
          componentName: "StepFour",
        },
      ];
    },

    isLastStep() {
      return this.step == this.steps.length - 1;
    },
  },

  methods: {
    ...mapActions(["timeoutTimerRestart"]),

    stepperChange(event) {
      const nextStep = event.value;

      if (nextStep > this.step && this.disableNextStep) return;

      // this.step = nextStep
    },

    nextSubmitButtonClick() {
      this.timeoutTimerRestart();
      
      this.formErrors = this.$refs.form.form.errors;
      if (this.isLastStep) {
        this.showErrors = false;
        const formValues = this.$refs.form.form.values;
        for (const property in formValues) {
          if (formValues[property] === null || formValues[property] === "") {
            this.showErrors = true;
          }
        }
        if (!this.showErrors) {
          alert(JSON.stringify(this.$refs.form.form.values, null, 2));
        }
      } else {
        this.step += 1;
        this.disableNextStep = true;
      }
    },
    prevButtonClick() {
      this.timeoutTimerRestart();
      this.step -= 1;
      this.disableNextStep = false;
    },

    stepContentChanged(event) {
      // console.log(event)

      const stepNumber = event.stepNumber;
      const stepActuallyChanged = event.stepActuallyChanged ?? false;

      if (stepActuallyChanged) {
        // console.log(`setting last changed step to ${stepNumber}`)
        this.lastChangedStep = stepNumber;
        // console.log(`last changed step: ${this.lastChangedStep}`)
      }

      this.disableNextStep = !event.stepValid;
    },
  },
};
</script>
>
