<template>
  <div class="text-white table-wrp" @keydown="closeMe">
    <!-- Make the whole table scrollable horizontally -->
    <div class="overflow-x-auto max-w-full">
      <table class="table-auto relative min-w-max">
        <thead class="top-0 sticky border-b bg-gray-700 z-20">
          <tr class="bg-[#D01E2F]">
            <!-- Sticky 1st header cell -->
            <th class="w-60 sticky left-0 z-30 bg-[#D01E2F]">Πακέτο</th>
            <template v-for="header in policies.horizontalHeaders" v-bind:key="header">
              <th colspan="2" class="text-center text-xl bg-[#D01E2F]">
                {{ header }}
              </th>
            </template>
          </tr>
          <tr class="border-b bg-[#D01E2F] b-2">
            <!-- Sticky 2nd row's first cell -->
            <th class="sticky left-0 z-30 bg-[#D01E2F]">Κάλυψη</th>
            <template v-for="header in policies.horizontalHeaders" v-bind:key="header">
              <th class="text-sm w-60">Insured</th>
              <th class="text-sm w-60">Excess</th>
            </template>
          </tr>
        </thead>

        <tbody class="overflow-y-auto divide-y w-60">
          <tr
            v-for="(header, rowid) in policies.verticalHeaders"
            :key="rowid"
            :class="visualRowIndex[rowid] % 2 === 0 ? 'bg-gray-800' : 'bg-gray-700'"
            class="border-b border-t"
          >
            <template v-if="rowSpans[rowid].show">
              <th
                :rowspan="rowSpans[rowid].span"
                scope="row"
                class="sticky left-0 z-20 text-center border-r border-l bg-[#D01E2F] align-middle"
              >
                <h3 class="py-3 whitespace-normal">
                  {{ header }}
                </h3>
              </th>
            </template>

            <template v-for="cell in policies.valuesByRowCol[rowid]" :key="cell">
              <td class="pl-4 border-r-2 border-dashed">
                <span class="block text-sm divide-0">
                  {{ cell.sums_insured }}
                </span>
              </td>
              <td class="pr-4 border-r-2 w-20 border-solid">
                <span class="block text-sm w-[270px]">
                  {{ cell.excess }}
                </span>
              </td>
            </template>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

  

<style scoped>
.table-wrp  {
  justify-content: center;
  max-height: 75vh;
  overflow-y: auto;
  display: block;
}

</style>

<script>

export default {
    name: 'PolicyComparator',
    components: {
    },

    props: [ 'policies' ],

    emits: [ 'requestClose' ],

    methods: {
        closeMe() {
            // console.log('requesting close')
            this.$emit('requestClose')
        }
    },
    computed: {
  rowSpans() {
    const headers = this.policies.verticalHeaders;
    const spans = Array(headers.length).fill({ show: true, span: 1 });

    let i = 0;
    while (i < headers.length) {
      const current = headers[i];

      if (current === null || current === '') {
        spans[i] = { show: false, span: 0 };
        i++;
        continue;
      }

      let span = 1;

      for (let j = i + 1; j < headers.length; j++) {
        const next = headers[j];
        if (next === null || next === '') {
          span++;
        } else {
          break;
        }
      }

      spans[i] = { show: true, span };

      for (let k = 1; k < span; k++) {
        spans[i + k] = { show: false, span: 0 };
      }

      i += span;
    }

    return spans;
  }, 
  visualRowIndex() {
    const result = [];
    let visualIndex = 0;
    for (let i = 0; i < this.policies.verticalHeaders.length; i++) {
      if (this.rowSpans[i].show) {
        result.push(visualIndex);
        visualIndex++;
      } else {
        result.push(visualIndex - 1); // belongs to previous visual row
      }
    }
    return result;
  }
}


}

 
</script>