<template>
  <form role="form" id="myform" accept-charset="utf-8">
    <div class="flex flex-col w-full p-4">
      <div class="flex items-stretch">
        <custom-card
          v-bind:title="'Στοιχεία εγγράφου προς αποστολή'"
          class="mt-2"
          extraClass="w-full"
        >
          <div class="grid gap-4 p-4">
            <div
              class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4"
            >
              <div>
                <label for="contractTypeInput" class="mx-2"
                  >Είδος αίτησης</label
                >
                <div
                  class="flex items-center bg-white rounded shadow-md mb-4 whitespace-nowrap"
                >
                  <k-dropdownlist
                    id="contractTypeInput"
                    class="h-8"
                    :data-items="applicationCategories"
                    :default-item="'Παρακαλώ επιλέξτε κλάδο'"
                    :value="applicationType"
                    @change="newApplicationTypeSelected"
                  />
                
                  <!-- <k-dropdownlist
                    v-if="nullApp==true"
                    id="contractTypeInput"
                    class="h-8 border-2 border-rose-600"
                    :data-items="applicationCategories"
                    :default-item="'Παρακαλώ επιλέξτε κλάδο'"
                    :value="applicationType"
                    @change="newApplicationTypeSelected"
                  /> -->
                </div>
                <div v-if="nullApp==true" class="text-rose-600 text-xs text-left italic">
                    <span>Η επιλογή κλάδου είναι υποχρεωτική</span>
                </div>
               
               
              </div>
              <div>
                <label
                  for="contractCustomerTaxRegistrationNumberInput"
                  class="mx-2"
                  >ΑΦΜ Ασφαλιζόμενου</label
                >
                <div
                  class="flex items-center bg-white rounded shadow-md mb-4 whitespace-nowrap"
                >
                <!-- @keydown.enter.prevent="handleEnter" -->
                  <input
                    type="text"
                    id="contractCustomerTaxRegistrationNumberInput"
                    class="h-8 w-full focus:outline-none px-4"
                    v-model="customerTaxRegistrationNumber"
                    v-on:blur="checkForVatInput($event)"
                    @keydown.enter.prevent="handleEnter" 
                  />
                  <!-- @keydown.enter.prevent="handleEnter" -->
                </div>
                <div v-if="nullTax==true" class="text-rose-600 text-xs text-left italic">
                    <span>το πεδίο ΑΦΜ είναι υποχεωτικό</span>
                </div>
               
                 
              </div>
                <!-- v-model="customerTaxRegistrationNumber" -->
                  <!-- v-on:keydown="verify"
                    v-on:blur="verify"
                    @keypress="checkForVatInput($event)" -->
             

              <div>
                <!-- <div v-if="isSimpleUser == false">
                  <div>
                    <label
                      for="contractCustomerTaxRegistrationNumberInput"
                      class="mx-2"
                      >Κωδικός Συνεργάτη</label
                    >
                    <div
                      class="flex items-center bg-gray-100 rounded shadow-md mb-4 whitespace-nowrap"
                    >
                      <k-dropdownlist
                        type="text"
                        id="contractCustomerTaxRegistrationNumberInput"
                        class="h-8 focus:outline-none w-60 px-4"
                        v-model="agentCode"
                        v-on:keyup.enter="verify"
                        v-on:blur="verify"
                        v-bind:disabled="isSimpleUser"
                        :data-items="allNNAgentCod ?? []"
                        @keypress="checkForVatInput($event)"
                      >
                      </k-dropdownlist>
                      <div>
                        <svg
                          v-if="verifiedAgent && !verifiedAgent.isDisabled"
                          class="inline mr-2 w-4 h-4 text-gray-200 dark:text-gray-600 stroke-green-500"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke-width="3"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <polyline points="20 6 9 17 4 12" />
                        </svg>

                        <svg
                          v-if="verifiedAgent && verifiedAgent.isDisabled"
                          class="group relative inline-block mr-2 w-4 h-4 text-gray-200 dark:text-gray-600 stroke-yellow-600"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke-width="3"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <title>Ο κωδικός συνεργάτη είναι ανενεργός</title>
                          <path
                            d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"
                          ></path>
                          <line x1="12" y1="9" x2="12" y2="13"></line>
                          <line x1="12" y1="17" x2="12.01" y2="17"></line>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div> -->
                <!-- v-elseif = isSimpleUser == true -->

                <div>
                  <div>
                    <label
                      for="contractCustomerTaxRegistrationNumberInput"
                      class="mx-2"
                      >Κωδικός συνεργάτη</label
                    >
                    <div
                      class="flex items-center bg-gray-100 rounded shadow-md mb-4 whitespace-nowrap"
                    >
                      <!-- <input
                        type="text"
                        id="contractCustomerTaxRegistrationNumberInput"
                        class="h-8 focus:outline-none w-60 px-4"
                        v-model="agentCode"
                        v-on:keyup.enter="verify"
                        v-on:blur="verify"
                        v-bind:disabled="isSimpleUser"
                        @keypress="checkForVatInput($event)"
                      /> -->
                      <!-- <input
                        type="text"
                        id="contractCustomerTaxRegistrationNumberInput"
                        class="h-8 focus:outline-none w-60 px-4"
                        v-model="verifiedAgent"
                        v-bind:disabled="true"
                        @keypress="checkForVatInput($event)"
                      /> -->
                  
                      <div :style="{display:'flex',alignItems:'center',gap:8+'px',fontFamily:'Arial, sans-serif'}">
                          <span> {{ agentCode }} </span>
                          <span> {{ agentName }} </span>

                        <svg
                          v-if="verifiedAgent && !verifiedAgent.isDisabled"
                          class="inline mr-2 w-4 h-4 text-gray-200 dark:text-gray-600 stroke-green-500"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke-width="3"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <polyline points="20 6 9 17 4 12" />
                        </svg>

                        <svg
                          v-if="verifiedAgent && verifiedAgent.isDisabled"
                          class="group relative inline-block mr-2 w-4 h-4 text-gray-200 dark:text-gray-600 stroke-yellow-600"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke-width="3"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <title>Ο κωδικός συνεργάτη είναι ανενεργός</title>
                          <path
                            d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"
                          ></path>
                          <line x1="12" y1="9" x2="12" y2="13"></line>
                          <line x1="12" y1="17" x2="12.01" y2="17"></line>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- v-if="isSimpleUser -->
              </div>
              <!-- <div>
                <div v-if="isSimpleUser == false">
                  <div>
                    <label
                      for="contractCustomerTaxRegistrationNumberInput"
                      class="mx-2"
                      >Επωνυμία Συνεργάτη</label
                    >
                    <input
                      type="text"
                      id="contractCustomerTaxRegistrationNumberInput"
                      class="h-8 focus:outline-none w-full px-4"
                      v-bind:value="verifiedAgent?.fullName"
                      disabled="true"
                    />
                  </div>
                </div>
                <div v-else-if="isSimpleUser == true">
                  <div>
                    <label
                      for="contractCustomerTaxRegistrationNumberInput"
                      class="mx-2"
                      >Επωνυμία Συνεργάτη</label
                    >
                    <input
                      type="text"
                      id="contractCustomerTaxRegistrationNumberInput"
                      class="h-8 focus:outline-none w-full px-4"
                      v-bind:value="verifiedAgent?.fullName"
                      disabled="true"
                    />
                  </div>
                </div>
                  v-if="isSimpleUser -->
              <!-- </div>  -->
            </div>
            <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4 ">
              <input
                class="block w-full  text-sm text-gray-900 border border-gray-300 rounded-sm cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                id="fileinput"
                type="file"
                ref="myFileInput"
                title="Επιλογή αρχείου"
                accept=".pdf"
                @change="filesChanged"
              />
              
            </div>
            <div class="grid grid-cols-4 sm:grid-cols-4 md:grid-cols-4 gap-4">
              <span v-if="nullFile==true"  class="text-rose-600 text-xs text-left italic" >Παρακαλώ επιλέξτε αρχείο</span>
            </div>
         
           
        
       
          

            <div
              class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4 mr-20"
            >
              <div>
                <label for="commentsInput" class="mx-2"
                  >Σχόλια (προαιρετικά)</label
                >
                <textarea
                  id="commentsInput"
                  class="focus:outline-none mx-1 resize-y w-full h-full"
                  v-model="comments"
                />
              </div>
            </div>
            <div class="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-2">
              <div>
                <spinner-button
                  ref="searchButton"
                  buttonClass="w-40 px-2 mt-10 py-1 text-white transition-colors duration-300 bg-red-400 enabled:bg-red-500 rounded-md shadow enabled:hover:bg-red-600 focus:outline-none"
                  idleText="ΑΠΟΣΤΟΛΗ"
                  activeText="ΔΙΑΔΙΚΑΣΙΑ ΣΕ ΕΞΕΛΙΞΗ"
                  finishedText="ΝΕΑ ΑΠΟΣΤΟΛΗ"
                  @idleClick="verifySendRequest"
                />
                <!-- v-bind:disabled="canUpload == false" -->
              </div>
            </div>
          </div>
        </custom-card>
      </div>
      <!-- Ιστορικό uploads -->
      <div class="flex flex-row">
        <custom-card v-bind:title="'Ιστορικό uploads'" extraClass="w-full">
          <div
            class="pt-4 overflow-auto text-center justify-center items-center place-items-center"
          >
            <table class="table-auto">
              <thead>
                <tr>
                  <th class="w-60">Α/Α Αίτησης</th>
                  <th class="w-60">Ημερομηνία/ώρα</th>
                  <th class="w-60">Κωδ. Συνεργάτη</th>
                  <th class="w-60">Κατηγορία αρχείου</th>
                  <th class="w-60">ΑΦΜ Ασφαλιζόμενου</th>
                  <th class="w-60">Αρχείο</th>
                  <th class="min-w-60">Σχόλια</th>
                </tr>
              </thead>
              <tbody class="whitespace-nowrap">
                <tr v-for="upload in uploads" v-bind:key="upload.intID">
                  <td class="px-4">{{ upload.fileInfo.intID }}</td>
                  <td class="px-4">
                    {{
                      new Date(upload.fileInfo.timestamp).toLocaleDateString(
                        "el-GR"
                      )
                    }}
                    {{
                      new Date(upload.fileInfo.timestamp).toLocaleTimeString(
                        "el-GR"
                      )
                    }}
                  </td>
                  <td class="px-4">
                    {{ upload.fileInfo.username }}
                  </td>
                  <td class="px-4">{{ upload.fileInfo.type }}</td>
                  <td class="px-4">
                    {{ upload.fileInfo.customerTaxRegistrationNumber }}
                  </td>
                  <td class="px-8 text-left whitespace-nowrap">
                    <div
                      @click="download(upload)"
                      class="flex flex-row group hover:cursor-pointer"
                    >
                      <span class="mx-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="group-hover:stroke-red-400"
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="#000000"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path
                            d="M3 15v4c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2v-4M17 9l-5 5-5-5M12 12.8V2.5"
                          />
                        </svg>
                      </span>
                      <span
                        class="group-hover:underline group-hover:text-red-400"
                        >{{ upload.fileInfo.originalFilename }}
                      </span>
                    </div>
                  </td>
                  <td class="px-4 text-left">{{ upload.fileInfo.comment }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </custom-card>
      </div>
    </div>
  </form>
</template>

<script>
import CustomCard from "@/components/CustomCard.vue";
import SpinnerButton from "@/components/SpinnerButton.vue";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { Upload } from "@progress/kendo-vue-upload";
import jwt_decode from "jwt-decode";
import fileDownload from "js-file-download";
import { Loader } from "@progress/kendo-vue-indicators";
function validateVATNumber(vatNumber) {
    if (vatNumber.length !== 9 || !/^\d{8}$/.test(vatNumber.substring(0, 8)) || !/[A-Z]/i.test(vatNumber[9])) {
        // console.log("i'm false");
        return false;
    }

    let digits = vatNumber.substring(0, 8).split('').map(Number);
  
    let checkLetter = vatNumber[8].toUpperCase();
    


    function transformDigit(position, digit) {
        if (position % 2 === 0) {  // Even positions (0-indexed)
            switch (digit) {
                case 0: return 1;
                case 1: return 0;
                case 2: return 5;
                case 3: return 7;
                case 4: return 9;
                case 5: return 13;
                case 6: return 15;
                case 7: return 17;
                case 8: return 19;
                case 9: return 21;
                default: return digit;
            }
        } else {  // Odd positions
            return digit;
        }
    }

    let transformedDigits = digits.map((digit, index) => transformDigit(index, digit));
    let totalSum = transformedDigits.reduce((sum, digit) => sum + digit, 0);

    // Calculate the check letter
    let remainder = totalSum % 26;
    let calculatedLetter = String.fromCharCode(remainder + 'A'.charCodeAt(0));

    // Compare calculated letter with the provided check letter
    return calculatedLetter === checkLetter;
}



export default {
  name: "FileUploadsView.vue",
  inject: ["$appConfig"],
  components: {
    loader: Loader,
    CustomCard,
    SpinnerButton,
    "k-dropdownlist": DropDownList,
    "k-upload": Upload,
  },

  data() {
    return {
      uploads: null,
      applicationType: null,
      comments: null,
      agentCode: null,
      verifiedAgent: null,
      customerTaxRegistrationNumber: null,
      applicationTypes: [],
      applicationCategories: [],
      allNNAgentCod: [],
      files: [],
      Upload_In_progress: false,
      agentName:null,
      nullTax: false,
      nullApp:false,
      nullFile:false
    };
  },

  // watch:{
  //   customerTaxRegistrationNumber(val){
  //     if(val == null){
  //       this.nullTax=true;
  //     }else{
  //       this.nullTax=false;
  //     }
  //   },
  //   nullApp(val){
  //     if(val == null){
  //       this.nullApp=true;
  //     }else{
  //       this.nullApp=false
  //     }
  //   },
  //   applicationCategory(val){
  //     if(val == null){
  //       this.nullApp=true;
  //     }else{
  //       this.nullApp=false;
  //     }
  //   }
  // },

  mounted() {
    this.$store.dispatch("switchPageTitle", "Αιτήσεις ασφάλισης");

    this.loadDropDownValues();
    this.loadUploads();
    this.verify();
  },

  computed: {
    isSimpleUser() {
      return this.userHasRole("PARTNER");
    },
    isAdminUser() {
      return this.userHasRole("KUA_ADMIN");
    },

    getUploadsUrl() {
      return new URL(
        this.$appConfig.API_UPLOADS_URL,
        this.$appConfig.API_BASE_URL
      );
    },

    newUploadUrl() {
      return new URL(
        this.$appConfig.API_UPLOADS_URL,
        this.$appConfig.API_BASE_URL
      );
    },

    dropDownValuesUrl() {
      return new URL(
        this.$appConfig.API_UPLOADS_DROPDOWN_VALUES_URL,
        this.$appConfig.API_BASE_URL
      );
    },

    canUpload() {
      if (this.Upload_In_progress == true){
        return false;
      } 
      if (this.verifiedAgent == null){
        return false;
      } 

      if (this.verifiedAgent.isDisabled == true){
        return false;
      }

      if (this.applicationType == null) {
      
        this.nullApp=true;
        if(this.files.length < 1){
          this.nullFile=true;
        }else{
          this.nullFile=false;
        }
        if (this.customerTaxRegistrationNumber?.length > 9 || this.customerTaxRegistrationNumber == null){
          this.nullTax=true;
        }
        return false;
      }
      if (this.files.length < 1) {
        this.nullFile=true;
        return false;
      }

      if (this.files.length > 1) {
        this.nullFile=true;
        return false;
      }

      if (this.customerTaxRegistrationNumber?.length > 9 || this.customerTaxRegistrationNumber == null){
        this.nullTax=true;
        return false;
      }
      return true;
    },

    getAgentUrl() {
      return new URL(
        this.$appConfig.API_GET_AGENT_URL,
        this.$appConfig.API_BASE_URL
      );
    },

    filegardenPostDownloadUrl() {
      return new URL("/api/filegarden/download", this.$appConfig.API_BASE_URL);
    },
  },

  methods: {
    userHasRole(roleName) {
      const rolePropertyName =
        "http://schemas.microsoft.com/ws/2008/06/identity/claims/role";
      let jwtDecode = jwt_decode(this.$store.state.token);

      let role = jwtDecode[rolePropertyName];

      return role === roleName;
    },

    loadDropDownValues() {
      const url = this.dropDownValuesUrl.href;

      this.axios
        .get(url, {
          headers: { Authorization: "Bearer " + this.$store.state.token },
        })
        .then((response) => {
          this.applicationCategories = response.data.categories;
          this.applicationTypes = response.data.types;
          this.allNNAgentCod = response.data.allNNAgentCod;
        });
    },
    verifySendRequest() {
     
      // console.log("eimai sto teleytaio bhma");
      if(this.canUpload == false){
        if (this.applicationType == null) {
          // console.log("h aithsh einai adeia");
          this.nullApp=true;
        if(this.files.length < 1){
          this.nullFile=true;
        }else{
          this.nullFile=false;
        }
          return false;
        }
        // console.log("to canUpload einai lathos");
        return false;
      }

      this.Upload_In_progress = true;
      this.$refs.searchButton.setStateActive();

      this.$swal
        .fire({
          title: "Να αποσταλεί η επιλεγμένη Αίτηση Ασφάλισης;",
          showDenyButton: true,
          confirmButtonText: `Ναι`,
          denyButtonText: `Όχι`,
          icon: "question",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.Upload_In_progress = true;
            this.sendUpload();
          } else {
            this.Upload_In_progress = false;
            this.$refs.searchButton.setStateIdle();
          }
        });
    },
    async sendUpload() {
      const toBase64 = (file) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        });

      const fileDataUrl = await toBase64(this.files[0]);
      const originalFilename = this.files[0].name;
      // console.log(originalFilename);

      let body = {
        originalFilename: originalFilename,
        contentDataUrl: fileDataUrl,
        type: this.applicationType,
        comments: this.comments,
        agentCode: this.agentCode,
        customerTaxRegistrationNumber: this.customerTaxRegistrationNumber,
      };

      const url = this.newUploadUrl.href;

     
      try {
        this.Upload_In_progress = true;

        await this.axios.post(url, body, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        });
        this.Upload_In_progress = false;
        this.$swal
          .fire("Επιτυχία", "Η Αίτηση Ασφάλισης εστάλει επιτυχώς", "success")
          .then(() => this.clearForm());
        this.$refs.searchButton.setStateIdle();
        this.loadUploads();
      } catch (error) {
        this.Upload_In_progress = false;
        this.$refs.searchButton.setStateIdle();
        // console.log(error);
        if (error.response.status == 400) {
          this.$swal.fire("Σφάλμα", error.response.data.error, "error");
        } else {
          this.$swal.fire(
            "Σφάλμα",
            "Το αίτημα δεν μπορεί να καταχωρηθεί.",
            "error"
          );
        }
      }
    },

    loadUploads() {
      const url = this.getUploadsUrl.href;

      this.axios
        .get(url, {
          headers: { Authorization: "Bearer " + this.$store.state.token },
        })
        .then((response) => {
          this.uploads = response.data;
          // console.log(this.uploads);
        });
    },

    newApplicationTypeSelected(evt) {
      this.applicationType = evt.value;
      this.nullApp=false;
    },

    filesChanged(evt) {
      if(evt.target.files==null){
        this.nullFile=true;
       
      }else{
        this.nullFile=false;
        this.files = evt.target.files;
      }
  
    },

    checkForVatInput(evt) {

     
      evt = evt ? evt : window.event;
      var charArray = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z"];

      if(charArray.includes(evt.target.value.slice(-1))){
        if(validateVATNumber(evt.target.value)){
          this.nullTax=false;
        }else{
          this.nullTax=true;
          return false;
        }

      }else{
  
          var str1 = evt.target.value.substring(0, 1)
          var str2 =evt.target.value.substring(1, 2)
          var str3=evt.target.value.substring(2, 3)
          var str4=evt.target.value.substring(3, 4)
          var str5=evt.target.value.substring(4, 5)
          var str6=evt.target.value.substring(5, 6)
          var str7=evt.target.value.substring(6, 7)
          var str8=evt.target.value.substring(7,8)
          var str9=evt.target.value.substring(8)

          var sum1 = 256 * str1
          var sum2 = 128 * str2
          var sum3 = 64 * str3
          var sum4 = 32 * str4
          var sum5 = 16 * str5
          var sum6 = 8 * str6
          var sum7 = 4 * str7
          var sum8 = 2 * str8

          var resSum = sum1+sum2+sum3+sum4+sum5+sum6+sum7+sum8
        
          var res = resSum%11;
          
          var charCode = evt.which ? evt.which : evt.keyCode;
          // console.log(charCode);
          // console.log(evt.target.value);
      
          if (res == str9 && evt.target.value!="") {
            
            this.nullTax=false
            
            // evt.preventDefault();
          }
          if(res != str9 || evt.target.value=="" || evt.target.value=="000000000"|| evt.target.value.length < 9 || evt.target.value.length > 9){
            
            this.nullTax=true
            return false;
          }
        // evt.preventDefault();
      }
    },
    handleEnter(event) {
      // console.log('Enter pressed: dont touch enter');
    },
    clearForm() {
      document.getElementById("myform").reset();
      this.applicationType = null;
      this.customerTaxRegistrationNumber = null;
      this.files = [];
      this.comments = null;
      this.nullFile=false;
      this.agentCode = null;
      this.verifiedAgent = null;
    },

    async verify() {
      // this.customerTaxRegistrationNumber
      const agent = await this.getAgent();
      this.verifiedAgent = agent;
      // console.log(this.verifiedAgent);
    },

    async getAgent(vat) {
 
      if (vat?.length > 9) return false;
      const url = this.getAgentUrl.href;
      // const url = "http://192.168.0.173:5286/api/agents";

      try {
        const response = await this.axios.get(url, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
          params: { vatNumber: vat },
        });
        // idresourceIdentifier;
        this.agentCode = response.data.partnerId;
        this.agentName = response.data.partnerName;
        // console.log(response.data.partnerId);
        return response.data;
      } catch {
        return null;
      }
    },

    async download(upload) {
      // this.$refs.filegardenPostFormTokenInput.value = token;
      // this.$refs.filegardenPostForm.submit();

      // this.$refs.filegardenPostFormTokenInput.value = '';

      const token = upload.downloadToken;

      const originalFileExtension =
        upload.fileInfo.originalFilename.match(/.+\.(.+?)$/)[1];
      const customerTaxRegistrationNumber =
        upload.fileInfo.customerTaxRegistrationNumber;

      const targetFilename = `ikeportal-application-${customerTaxRegistrationNumber}.${originalFileExtension}`;

      try {
        const response = await this.axios.post(
          this.filegardenPostDownloadUrl,
          { token: token },
          {
            responseType: "blob",
          }
        );
        const data = response.data;

        fileDownload(data, targetFilename);
      } catch (error) {
        // console.log(error);
      }
    },
  },
};
</script>
