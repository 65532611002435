<template>
  <div>
    <div>
      <nav class="bg-gray-200 h-screen w-5 no-scrollbar">
        <ul>
          <li v-if="canAccessUsers">
            <a href="">
              <div class="my-3">
                <router-link to="/users">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-5 h-5"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#000000"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                    <circle cx="9" cy="7" r="4"></circle>
                    <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                    <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                  </svg>
                </router-link>
              </div>
            </a>
          </li>

          <li
            class="relative my-3"
            id="sidenavSecEx2"
            v-if="canAccessReports"
            @click="$emit('showIt')"
          >
            <a
              data-bs-toggle="collapse"
              data-bs-target="#collapseSidenavSecEx2"
              aria-expanded="false"
              aria-controls="collapseSidenavSecEx2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-5 h-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M2.25 18L9 11.25l4.306 4.307a11.95 11.95 0 015.814-5.519l2.74-1.22m0 0l-5.94-2.28m5.94 2.28l-2.28 5.941"
                />
              </svg>
            </a>
            <ul
              class="relative accordion-collapse collapse"
              id="collapseSidenavSecEx2"
              aria-labelledby="sidenavSecEx2"
              data-bs-parent="#sidenavSecExample"
            >
              <li class="relative" v-if="canAccessProductionReport">
                <router-link
                  to="/reports/production"
                  class="flex items-center text-xs py-4 pl-12 pr-6 h-6 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:bg-red-50 transition duration-300 ease-in-out"
                  data-mdb-ripple-color="primary"
                  >Παραγωγή</router-link
                >
              </li>
              <li class="relative" v-if="canAccessToBeExpiredReport">
                <router-link
                  to="/reports/to-be-expired"
                  class="flex items-center text-xs py-4 pl-12 pr-6 h-6 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:bg-red-50 transition duration-300 ease-in-out"
                  data-mdb-ripple-color="primary"
                  >Ληξιάρια</router-link
                >
              </li>
              <li class="relative" v-if="canAccessUnpaidReport">
                <router-link
                  to="/reports/unpaid"
                  class="flex items-center text-xs py-4 pl-12 pr-6 h-6 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:bg-red-50 transition duration-300 ease-in-out"
                  data-mdb-ripple-color="primary"
                  >Ανεξόφλητα</router-link
                >
              </li>
              <li class="relative" v-if="canAccessPaidReport">
                <router-link
                  to="/reports/paid"
                  class="flex items-center text-xs py-4 pl-12 pr-6 h-6 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:bg-red-50 transition duration-300 ease-in-out"
                  data-mdb-ripple-color="primary"
                  >Εξοφλημένα</router-link
                >
              </li>
              <li class="relative" v-if="canAccessCancelledReport">
                <router-link
                  to="/reports/cancelled"
                  class="flex items-center text-xs py-4 pl-12 pr-6 h-6 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:bg-red-50 transition duration-300 ease-in-out"
                  data-mdb-ripple-color="primary"
                  >Ακυρωμένα</router-link
                >
              </li>
            </ul>
          </li>
          <li
            class="relative my-3"
            id="sidenavSecEx4"
            v-if="canAccessDocuments"
            @click="$emit('showIt')"
          >
            <a
              data-bs-toggle="collapse"
              data-bs-target="#collapseSidenavSecEx4"
              aria-expanded="false"
              aria-controls="collapseSidenavSecEx4"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-5 h-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                />
              </svg>
            </a>
            <ul
              class="relative accordion-collapse collapse"
              id="collapseSidenavSecEx4"
              aria-labelledby="sidenavSecEx4"
              data-bs-parent="#sidenavSecExample"
            >
              <li class="relative">
                <div
                  class="flex items-center text-sm py-4 px-5 h-12 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:bg-red-50 transition duration-300 ease-in-out"
                  href="#!"
                >
                  <router-link
                    to="/applications"
                    class="flex items-center text-xs py-4 pl-12 pr-6 h-6 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:bg-red-50 transition duration-300 ease-in-out"
                    data-mdb-ripple-color="primary"
                    >Έντυπα αιτήσεων</router-link
                  >
                </div>
              </li>
              <li class="relative">
                <div
                  class="flex items-center text-sm py-4 px-5 h-12 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:bg-red-50 transition duration-300 ease-in-out"
                  href="#!"
                >
                  <router-link
                    to="/uploads"
                    class="flex items-center text-xs py-4 pl-12 pr-6 h-6 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:bg-red-50 transition duration-300 ease-in-out"
                    data-mdb-ripple-color="primary"
                    >Αποστολή αιτήσεων</router-link
                  >
                </div>
              </li>
            </ul>
          </li>
          <li
            class="relative my-3"
            id="sidenavSecEx5"
            v-if="canAccessTravel"
            @click="$emit('showIt')"
          >
            <a
              data-bs-toggle="collapse"
              data-bs-target="#collapseSidenavSecEx5"
              aria-expanded="false"
              aria-controls="collapseSidenavSecEx5"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="36"
                height="36"
                viewBox="0 0 36 36"
                class="w-5 h-5"
              >
                <path
                  fill="currentColor"
                  d="M6.25 11.5L12 13.16l6.32-4.59l-9.07.26a.52.52 0 0 0-.25.08l-2.87 1.65a.51.51 0 0 0 .12.94Z"
                  class="clr-i-solid clr-i-solid-path-1"
                />
                <path
                  fill="currentColor"
                  d="M34.52 6.36L28.22 5a3.78 3.78 0 0 0-3.07.67L6.12 19.5l-4.57-.2a1.25 1.25 0 0 0-.83 2.22l4.45 3.53a.55.55 0 0 0 .53.09c1.27-.49 6-3 11.59-6.07l1.12 11.51a.55.55 0 0 0 .9.37l2.5-2.08a.76.76 0 0 0 .26-.45l2.37-13.29c4-2.22 7.82-4.37 10.51-5.89a1.55 1.55 0 0 0-.43-2.88Z"
                  class="clr-i-solid clr-i-solid-path-2"
                />
                <path fill="none" d="M0 0h36v36H0z" />
              </svg>
            </a>
            <ul
              class="relative accordion-collapse collapse"
              id="collapseSidenavSecEx5"
              aria-labelledby="sidenavSecEx5"
              data-bs-parent="#sidenavSecExample"
            >
              <li class="relative">
                <div
                  class="flex items-center text-sm py-4 px-4 h-12 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:bg-red-50 transition duration-300 ease-in-out"
                  href="#!"
                >
                  <router-link
                    to="/travel/newquote"
                    class="flex items-center text-xs py-4 pl-12 pr-6 h-6 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:bg-red-50 transition duration-300 ease-in-out"
                    data-mdb-ripple-color="primary"
                    >Νέα προσφορά</router-link
                  >
                </div>
              </li>
              <li class="relative">
                <div
                  class="flex items-center text-sm py-4 px-4 h-12 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:bg-red-50 transition duration-300 ease-in-out"
                  href="#!"
                >
                  <router-link
                    to="/travel/saved-offers"
                    class="flex items-center text-xs py-4 pl-12 pr-6 h-6 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:bg-red-50 transition duration-300 ease-in-out"
                    data-mdb-ripple-color="primary"
                    >Αποθηκευμένες προσφορές</router-link
                  >
                </div>
              </li>
              <li class="relative">
                <div
                  class="flex items-center text-sm py-4 px-4 h-12 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:bg-red-50 transition duration-300 ease-in-out"
                  href="#!"
                >
                  <router-link
                    to="/travel/policy-requests"
                    class="flex items-center text-xs py-4 pl-12 pr-6 h-6 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:bg-red-50 transition duration-300 ease-in-out"
                    data-mdb-ripple-color="primary"
                    >Απεσταλμένες αιτήσεις</router-link
                  >
                </div>
              </li>
            </ul>
          </li>
          <li
            class="relative"
            id="sidenavSecEx5"
            v-if="canAccessProperty"
            @click="$emit('showIt')"
          >
            <a
              class="items-center text-sm pb-4 overflow-hidden text-ellipsis rounded duration-300 ease-in-out cursor-pointer"
              data-bs-toggle="collapse"
              data-bs-target="#collapseSidenavSecEx6"
              aria-expanded="false"
              aria-controls="collapseSidenavSecEx6"
            >
              <!-- <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-3 h-3 mr-3"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#000000"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path
                  d="m 3.326 21.15 c 0.002 -0.806 0.46 -1.594 1.206 -2.476 l -4.314 -2.728 c -0.272 -0.118 -0.266 -0.284 -0.108 -0.48 l 0.914 -0.78 c 0.166 -0.102 0.342 -0.146 0.532 -0.094 l 5.324 0.9 l 4.436 -4.804 l -10.356 -7.006 c -0.262 -0.154 -0.284 -0.328 -0.014 -0.53 l 1.494 -1.192 l 13.5 3.794 l 3.988 -4.264 c 1.338 -1.158 2.638 -1.676 3.636 -1.43 c 0.55 0.136 0.744 0.3 0.914 0.816 c 0.33 1.012 -0.182 2.372 -1.392 3.772 l -4.264 3.988 l 3.794 13.5 l -1.192 1.494 c -0.202 0.268 -0.376 0.246 -0.53 -0.014 l -7.008 -10.354 l -4.804 4.434 l 0.9 5.324 c 0.052 0.188 0.01 0.364 -0.094 0.532 l -0.78 0.914 c -0.194 0.158 -0.362 0.164 -0.48 -0.108 l -2.728 -4.314 c -0.886 0.748 -1.674 1.206 -2.484 1.206 c -0.074 -0.002 -0.09 -0.028 -0.09 -0.1 l 0 0 z"
                />
              </svg> -->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
            </a>
            <ul
              class="relative accordion-collapse collapse"
              id="collapseSidenavSecEx6"
              aria-labelledby="sidenavSecEx6"
              data-bs-parent="#sidenavSecExample"
            >
              <li class="relative">
                <div
                  class="items-center text-sm h-12 overflow-hidden rounded hover:text-red-500 hover:bg-red-50 transition duration-300 ease-in-out"
                  href="#!"
                >
                  <router-link
                    to="/property/NewPropertyQuote"
                    class="items-center text-xs py-4 pl-12 pr-6 h-6 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-red-500 hover:bg-red-50 transition duration-300 ease-in-out"
                    data-mdb-ripple-color="primary"
                    >Νέα προσφορά</router-link
                  >
                </div>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
import jwt_decode from "jwt-decode";

export default {
  inject: ["$appConfig"],
  name: "NavSideBarShrinked",
  computed: {
    canAccessUsers() {
      return this.userHasRole("KUA_ADMIN");
    },
    canAccessPortfolios() {
      return (
        this.userHasRole("KUA_ADMIN") || this.userHasRole("NN_SALESSUPPORT")
      );
    },
    canAccessProperty() {
      return this.userHasRole("KUA_ADMIN");
    },
    canAccessReports() {
      return (
        this.userHasRole("VIEW_ONLY") ||
        this.userHasRole("KUA_ADMIN") ||
        this.userHasRole("KUA_USER") ||
        this.userHasRole("PARTNER")
      );
    },

    canAccessProductionReport() {
      return this.canAccessReports;
    },

    canAccessTravel() {
      return this.userHasRole("KUA_ADMIN") || this.userHasRole("KUA_USER");
    },

    canAccessDocuments() {
      return (
        this.userHasRole("PARTNER") ||
        this.userHasRole("KUA_ADMIN") ||
        this.userHasRole("KUA_USER")
      );
    },

    canAccessToBeExpiredReport() {
      return this.canAccessReports;
    },

    canAccessPaidReport() {
      return this.canAccessReports;
    },

    canAccessUnpaidReport() {
      return this.canAccessReports;
    },

    canAccessCancelledReport() {
      return this.canAccessReports;
    },
    showDevFeatures() {
      return (
        this.$appConfig.SHOW_DEV_FEATURES == "true" ||
        this.$appConfig.SHOW_DEV_FEATURES == true
      );
    },
  },
  methods: {
    test() {
      // console.log(this.$store.state.token);
    },
    userHasRole(roleName) {
      const rolePropertyName =
        "http://schemas.microsoft.com/ws/2008/06/identity/claims/role";
      let jwtDecode = jwt_decode(this.$store.state.token);

      let role = jwtDecode[rolePropertyName];

      return role === roleName;
    },
  },
};
</script>
