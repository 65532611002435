<template>
  <k-dialog v-if="overlayHidden == false" :title="'Authenticator QR'" @close="closeOverlay">
    <div class="flex flex-col">
      <div class="justify-center items-center text-center">
        <vue-qrcode v-if="qrCodeData" :value="qrCodeData" :options="{ width: 300 }" class="p-8"></vue-qrcode>
      </div>
      <k-dialog-actions-bar>
          <k-button :theme-color="'primary'" @click="closeOverlay">ΕΝΤΑΞΕΙ</k-button>
      </k-dialog-actions-bar>
    </div>
  </k-dialog>




  <div class="flex flex-row">
    <custom-card title="ΣΤΟΙΧΕΙΑ ΛΟΓΑΡΙΑΣΜΟΥ">
      <div>

        <!-- FIRST ROW -->
        <div class="flex flex-row mt-8 items-center justify-center">
          <div class="flex flex-col mx-2 w-60">
              <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="usernameInput">USERNAME</label>
              <k-input id="usernameInput"  v-bind:value="user.username" v-bind:disabled="true"/>
          </div>

          <div class="flex flex-col mx-2 w-60">
              <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="fullNameInput">ΟΝΟΜΑ</label>
              <k-input id="fullNameInput"  v-bind:value="user.userFullName" v-bind:disabled="true" />
          </div>
        </div>


        <!-- SECOND ROW -->
        <div class="flex flex-row mt-8 items-center justify-center">
          <div class="flex flex-col mx-2 w-60">
            <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="emailInput">E-MAIL</label>
            <k-input id="emailInput" type="email" v-bind:placeholder="user.email" v-model="newEmail" />
            <!-- @blur="checkEmailInputs" -->
            <span v-if="nullEmail==true"  class="text-rose-600 text-xs text-left italic">
                The email addresses don't match
            </span>
          </div>

          <div class="flex flex-col mx-2 w-60">
              <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="verifyEmailInput">ΕΠΙΒΕΒΑΙΩΣΗ E-MAIL</label>
              <k-input id="verifyEmailInput" type="email" v-bind:placeholder="user.email" v-model="newEmailVerification" @blur="checkEmailInputs"  />
              <span v-if="nullEmail==true" class="text-rose-600 text-xs text-left italic">
                The email addresses don't match
            </span>
          </div>
        </div>


        <!-- THIRD ROW -->
        <div class="flex flex-row mt-8 items-center justify-center">
          <div class="flex flex-col mx-2 w-60">
              <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="passwordInput">ΚΩΔΙΚΟΣ</label>
              <k-input id="passwordInput" placeholder="********" v-model="newPassword" />
              <!-- @blur="checkPasswordInputs" -->
              <!-- v-bind:class="{ 'k-invalid' : passwordMismatch }" -->
              <span v-if="nullPassword==true" class="text-rose-600 text-xs text-left italic">
                The passwords don't match
            </span>
          </div>

          <div class="flex flex-col mx-2 w-60">
              <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="verifyPasswordInput">ΕΠΙΒΕΒΑΙΩΣΗ ΚΩΔΙΚΟΥ</label>
              <k-input id="verifyPasswordInput" placeholder="********" v-model="newPasswordVerification" @blur="checkPasswordInputs"/>
              <span v-if="nullPassword==true" class="text-rose-600 text-xs text-left italic">
                The passwords don't match
            </span>
          </div>

        </div>


        <div class="mx-3 flex my-6 text-center justify-center">
          <a href="#" @click.prevent="showOverlay" class="text-sm text-red-600 hover:underline focus:text-red-800">Εμφάνιση κωδικού QR για εφαρμογές authenticator</a>
        </div>

        <div class="-mx-3 md:flex mt-6 text-center justify-center">
          <!-- <k-button :theme-color="'primary'" @click="saveCredentials" class="px-4">ΑΠΟΘΗΚΕΥΣΗ</k-button> -->
          <spinner-button 
            buttonClass="w-40 px-2 py-1 text-white transition-colors duration-300 bg-red-500 rounded-md shadow hover:bg-red-600 focus:outline-none focus:ring-blue-200 focus:ring-4" 
            idleText="ΑΠΟΘΗΚΕΥΣΗ"
            ref="saveButton"
            @idleClick="saveCredentials2"
            />
        </div> 
      </div>
    </custom-card>
  </div>
        
</template>

<script>
// @ is an alias to /src
import VueQrcode from '@chenfengyuan/vue-qrcode';
import { Dialog, DialogActionsBar } from '@progress/kendo-vue-dialogs';
import { Button } from '@progress/kendo-vue-buttons';
import CustomCard from '@/components/CustomCard.vue';
import { Input } from '@progress/kendo-vue-inputs';
import SpinnerButton from '@/components/SpinnerButton.vue'

export default {
  name: 'UserAccountView',
  inject:['$appConfig'],
  components: {
    VueQrcode,
    'k-dialog': Dialog,
    'k-dialog-actions-bar': DialogActionsBar,  
    'k-button' : Button,
    'k-input' : Input,
    CustomCard,
    SpinnerButton
  },
  data() {
    return {
      newEmail: null,
      newEmailVerification: null,

      newPassword: null,
      newPasswordVerification:null,

      overlayHidden: true,
      qrCode: null,
      user: {
          id: null,
          username: null,
          email: null,
          totpSecret: null
      },

      emailMismatch: false,
      passwordMismatch: false,
      nullEmail:false,
      nullPassword:false
    }
  },

  watch: {
    // newEmail : function (val) { this.checkEmailInputs() },
    // newEmailVerification: function (val) { this.checkEmailInputs() },
    // newPassword: function (val) { this.checkPasswordInputs() },
    // newPasswordVerification: function (val) { this.checkPasswordInputs() },

  },

  methods: {

    checkEmailInputs() {
      if ((this.newEmail ?? '') != (this.newEmailVerification ?? '')){
        this.nullEmail=true;
        this.emailMismatch = true;
        return false;
      }
      else{
        this.nullEmail=false;
        this.emailMismatch = false
      }
    },

    checkPasswordInputs() {
      if ((this.newPassword ?? '') != (this.newPasswordVerification ?? '')){
        this.nullPassword=true;
        this.passwordMismatch = true;
      }else{
        this.nullPassword=false;
        this.passwordMismatch = false;
      }
    },

    async saveCredentials2() {
      const emailAddressChange = this.newEmail?.length > 0
      const passwordChange = this.newPassword?.length > 0
      if(this.newEmail!=this.newEmailVerification){
        this.nullEmail=true;
        // this.$swal.fire('Error', "The email addresses don't match", 'error');
        return false;
      }else{
        this.nullEmail=false
      }
      if(this.newPassword!= this.newPasswordVerification){
        this.nullPassword=true;
        // this.$swal.fire('Error', "The passwords addresses don't match", 'error');
        return false;
      }else{
        this.nullPassword=false;
      }
      if (false == (emailAddressChange || passwordChange)) {
        return
      }
      var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if(this.newEmail?.length>0 && !this.newEmail.match(validRegex)){
        return false;
        
      }
      if(this.newEmailVerification?.length>0 && !this.newEmailVerification.match(validRegex)){
        return false;
      }
      // console.log(this.emailMismatch);


      this.$refs.saveButton.setStateActive()

      let requestBody = {}

      if (emailAddressChange)
        requestBody.email = this.newEmail

      if (passwordChange)
        requestBody.password = this.newPassword

      this.axios.post(
        this.updateCredentialsUrl.href,
        requestBody,
        {
          headers: { Authorization: 'Bearer ' + this.$store.state.token }
        }
      ).then(response => { this.$swal.fire('Success', 'Your credentials have been updated', 'success'); this.$refs.saveButton.setStateIdle(); })
      .catch(error => { this.$swal.fire('Error', 'Your credentials cannot be updated', 'error'); this.$refs.saveButton.setStateIdle(); })
    },

    async saveCredentials() {
      if (this.newEmail?.length > 0 && this.newEmail !== this.newEmailVerification) {
        await this.$swal.fire('Error', "The email addresses don't match", 'error');
        return;
      } 


      if (this.newPassword?.length > 0 && this.newPassword !== this.newPasswordVerification) {
        await this.$swal.fire('Error', "The passwords don't match", 'error');
        return;
      }

      const emailAddressChange = this.newEmail?.length > 0
      const passwordChange = this.newPassword?.length > 0

      if (false == (emailAddressChange || passwordChange)) {
        return
      }

      let requestBody = {}

      if (emailAddressChange)
        requestBody.email = this.newEmail

      if (passwordChange)
        requestBody.password = this.newPassword

      this.axios.post(
        this.updateCredentialsUrl.href,
        requestBody,
        {
          headers: { Authorization: 'Bearer ' + this.$store.state.token }
        }
      ).then(response => this.$swal.fire('Success', 'Your credentials have been updated', 'success'))
      .catch(error => this.$swal.fire('Error', 'Your credentials cannot be updated' , 'error'))
      
    },

    closeOverlay() {
        this.overlayHidden = true;
    },
    showOverlay() {
        this.overlayHidden = false;
    }
  },

  mounted() {
    this.axios.get(
        this.whoAmIUrl.href, {
            headers: {
                'Authorization' : 'Bearer ' + this.$store.state.token
            }
        }
        ).then(response => this.user = response.data)
  },

  computed: {
    whoAmIUrl() {
        return new URL(this.$appConfig.API_WHOAMI_URL, this.$appConfig.API_BASE_URL)
    },

    updateCredentialsUrl() {
      return new URL(this.$appConfig.API_UPDATE_CREDENTIALS_URL, this.$appConfig.API_BASE_URL);
    },

    qrCodeData() {
        if (this.user.totpSecret == null)
            return null

        let otpUri = `otpauth://totp/KUA:${this.user.username}?secret=${this.user.totpSecret}&issuer=KUA`

        return otpUri;
    }
  }
}
</script>
