<template>
  <div
  :style="{
      backgroundColor: isIke ? 'rgb(241,238,250)' : 'rgb(229,231,235)',
    }"
    class="asd mx-2 my-8 shadow-md rounded-lg"
    v-bind:class="extraClass"
  >
    <!-- //v1.0.3.4  Αλλαγή χρώματος γραμμής -->
    <div class="px-2 py-2 divide-y divide-y-2 divide-[#d1182b]">
      <!-- //v1.0.3.4  Αλλαγή χρώματος γραμμής -->
      <p class="px-2 mb-1 text-gray-900 font-semibold text-left">{{ title }}</p>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: ["title", "extraClass"],
  inject: ["$appConfig"],
  data() {
    return {
      isIke:false,
    };
  },
  mounted() {
    if(this.$appConfig.APP_COMPANY_NAME=="ΚΑΡΑΒΙΑΣ ΑΣΦΑΛΙΣΤΙΚΟΙ ΠΡΑΚΤΟΡΕΣ ΙΚΕ"){
      // console.log("eimai ike")
      this.isIke=true
    }else{
      // console.log("eimai AE")
    }
  },
};
</script>
