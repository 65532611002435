<template>
  <!--------------------//v1.0.3.3  Αλλαγή στη στοίχιση----------------------->
  <div class="flex flex-row w-full p-4 mt-6">
    <!--------------------//v1.0.3.3  Αλλαγή στη στοίχιση----------------------->

    <custom-card
      title="Αποθηκευμένες προσφορές - Κριτήρια αναζήτησης"
      class="mt-2"
      extraClass="w-full"
    >
      <div>
        <div class="flex flex-row mt-8 items-center justify-center">
          <div class="flex flex-col mx-2 w-60">
            <label
              class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2"
              for="startDateInput"
              >ΑΠΟ ΗΜΕΡΟΜΗΝΙΑ</label
            >
            <k-date-picker
              id="startDateInput"
              :format="'dd/MM/yyyy'"
              :value="startDateFilter"
              @change="startDateChanged"
            >
              <span class="close-btn">XXXX</span>
            </k-date-picker>
          </div>

          <div class="flex flex-col mx-2 w-60">
            <label
              class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2"
              for="endDateInput"
              >ΕΩΣ ΗΜΕΡΟΜΗΝΙΑ</label
            >
            <k-date-picker
              id="endDateInput"
              :format="'dd/MM/yyyy'"
              :value="endDateFilter"
              @change="endDateChanged"
            >
              <!-- <span class="close-btn" @click="clear">WTFISTHISSHIT</span> -->
            </k-date-picker>
          </div>
        </div>

        <!-- <k-button class="my-8 mx-2 px-2 w-40" :theme-color="'primary'" @click="loadData">ΑΝΑΖΗΤΗΣΗ</k-button> -->
        <spinner-button
          ref="searchButton"
          buttonClass="w-40 px-2 py-1 text-white transition-colors duration-300 bg-red-500 rounded-md shadow enabled:hover:bg-red-600 focus:outline-none"
          idleText="ΑΝΑΖΗΤΗΣΗ"
          @idleClick="loadData"
        />
        <k-button
          class="my-8 mx-2 px-2 w-40"
          :theme-color="'secondary'"
          @click="clearFilters"
          >ΚΑΘΑΡΙΣΜΑ</k-button
        >
      </div>
    </custom-card>
  </div>

  <div class="flex flex-row overflow-auto m-2 p-4" style="max-width: 82vw">
    <k-grid
      class="mt-2"
      ref="grid"
      :data-items="processedGridData"
      :sortable="true"
      :sort="sort"
      :filterable="true"
      :filter="filter"
      :pageable="false"
      :skip="skip"
      :take="take"
      @sortchange="sortChangeHandler"
      @filterchange="filterChangeHandler"
      @pagechange="pageChangeHandler"
      :columns="columns"
    >
      <template v-slot:myTemplate="{ props }">
        <div style="display: table-cell; vertical-align: middle" class="p-2">
          <!-- <k-button class="" :theme-color="'primary'" @click="switchToCreatePolicyForm(props.dataItem)">Request policy</k-button> -->
          <!-- <k-split-button :class="'text-red-500'" fill-mode="flat" :theme-color="'primary'" :items="props.dataItem.quotes" :textField="'buttonText'">Αίτηση έκδοσης</k-split-button> -->
          <k-dropdown-button
            :theme-color="'primary'"
            :fill-mode="'link'"
            :items="props.dataItem.quotes"
            :textField="'buttonText'"
            :text="'Καταχώρηση αίτησης'"
            @itemclick="switchToCreatePolicyForm"
          />
        </div>
      </template>

      <template v-slot:quotesTemplate="{ props }">
        <div style="display: table-cell; vertical-align: middle" class="p-2">
          <a v-bind:href="props.dataItem.DisplayOfferEN" target="_blank">EN</a>
          <span class="px-2">|</span>
          <a v-bind:href="props.dataItem.DisplayOfferGR" target="_blank">GR</a>
        </div>
      </template>
    </k-grid>
  </div>
</template>

<script>
import { Grid } from "@progress/kendo-vue-grid";
import {
  Button,
  SplitButton,
  DropDownButton,
} from "@progress/kendo-vue-buttons";

import CustomCard from "@/components/CustomCard.vue";

import apiClient from "@/apiClient";
import { mapActions } from "vuex";

import { DatePicker } from "@progress/kendo-vue-dateinputs";

import SpinnerButton from "@/components/SpinnerButton.vue";
import { process } from "@progress/kendo-data-query";

export default {
  name: "SavedTravelOffersView",

  components: {
    "k-grid": Grid,
    "k-button": Button,
    "k-split-button": SplitButton,
    "k-dropdown-button": DropDownButton,
    "k-date-picker": DatePicker,
    CustomCard,
    SpinnerButton,
  },

  async mounted() {
    const token = await apiClient.login(null, null);
    this.apiToken = token;

    const today = new Date();
    today.setHours(0, 0, 0);

    this.endDateFilter = today;

    const fromDateValue = new Date();
    fromDateValue.setHours(0, 0, 0);
    fromDateValue.setDate(today.getDate() - 30);

    this.startDateFilter = fromDateValue;

    this.loadData();
  },

  data() {
    return {
      apiToken: null,

      startDateFilter: null,
      endDateFilter: null,

      sort: [{ field: "createdAt", dir: "desc" }],
      filter: null,
      skip: 0,
      take: 20,
      columns: [
        { title: "Actions", cell: "myTemplate", filterable: false, width: 180 },

        // { field: 'status', title: 'Status' },
        //{ field: 'id', editable: false, title: 'ID', width: '300px' },
        // { field: 'NNAgentCode', editable: false, title: 'NN Agent Code', hidden: true },
        // { field: 'saved_offers_id', title: 'Offer ID' },

        {
          field: "createdAt",
          title: "Saved At",
          filter: "date",
          type: "date",
          // format: "{0:dd/MM/yyyy}",
        },

        { field: "offer_name", title: "Offer Name" },

        {
          title: "Quotes",
          cell: "quotesTemplate",
          filterable: false,
          width: 100,
        },

        { field: "residence", title: "Residence" },
        { field: "destination", title: "Destination" },
        { field: "tripDates", title: "Trip Dates" },

        // { field: 'startDate', title: 'Start Date' },
        // { field: 'endDate', title: 'End Date'},
        // { field: 'policytypeName', title: 'Policy Name' },
        // { field: 'GrossPriceNum', title: 'Gross Price', format: '{0:n2}', filter: 'numeric', className: 'kgrid-td-rightalign' }
      ],
      offers: [],
    };
  },

  computed: {
    getTravelPolicyRequestsUrl() {
      return new URL(
        this.$appConfig.API_GET_TRAVEL_POLICY_REQUESTS_URL,
        this.$appConfig.API_BASE_URL
      );
    },

    hideAgentIdField() {
      return true;
    },

    processedGridData() {
      return process(this.offers, {
        sort: this.sort,
        filter: this.filter,
        take: this.take,
        skip: this.skip,
      });
    },
  },

  methods: {
    loadData() {
      const dateOptions = { month: "2-digit", day: "2-digit", year: "numeric" };
      const fromDate = this.startDateFilter?.toLocaleDateString(
        "el-GR",
        dateOptions
      );
      const toDate = this.endDateFilter?.toLocaleDateString(
        "el-GR",
        dateOptions
      );
      
      const nnAgentCode =
        this.$store.state.userAgentCode ?? this.$store.state.user;

      this.loadSavedOffers(this.apiToken, fromDate, toDate);
    },

    clearFilters() {
      this.startDateFilter = null;
      this.endDateFilter = null;
    },

    startDateChanged(event) {
      this.startDateFilter = event.value;
    },

    endDateChanged(event) {
      this.endDateFilter = event.value;
    },

    ...mapActions(["setCurrentQuoteForPolicyCreation"]),
    
    async loadSavedOffers(token, from, to) {
      const offersResponse = await apiClient.getTravelSavedOffers(
        token,
        // nnAgentCode,
        from,
        to
      );

      let offers = [];
      // console.log(offersResponse);
      //offersResponse.map(offer => ({...offer, GrossPriceNum: Number(offer.GrossPrice)}));

      offersResponse.forEach(function (item) {
        const quoteDetails = {
          QuoteID: item.QuoteID,
          policyTypeName: item.policytypeName,
          GrossPriceNum: Number(item.GrossPrice),

          buttonText: `${item.policytypeName} (€ ${Number(
            item.GrossPrice
          ).toFixed(2)})`,
        };

        const offer = offers.find(
          (x) => x.saved_offers_id == item.saved_offers_id
        );
        // console.log(offer);
        
        // const localeString = createdAt.toLocaleString();
        
        // new Date(Date.UTC(item.Saved_Date.year, item.Saved_Date.month - 1, item.Saved_Date.day))
        if (offer == null) {
          const newOffer = {
            saved_offers_id: item.saved_offers_id,
            offer_name: item.offer_name,
            startDate: item.startDate,
            endDate: item.endDate,
            tripDates: `${item.startDate} - ${item.endDate}`,
            residence: item.residenceName,
            destination: item.destinationName,
            ageBandsSum: item.AgeBands_Description,
            DisplayOfferGR: item.DisplayOfferGR,
            DisplayOfferEN: item.DisplayOfferEN,
            //v1.0.1.7 Προσθήκη key και value για Insured_Category στην function loadSavedOffers()
            Insured_Category: item.insuredCategory,
            //v1.0.1.7 Προσθήκη key και value για Insured_Category στην function loadSavedOffers()
            createdAt: item.Saved_Date,
            // createdAt: new Date(item.Saved_Date.split("/").reverse().join("/")),
            quotes: [quoteDetails],
          };
          
          offers.push(newOffer);
        } else {
          offer.quotes.push(quoteDetails);
        }
      });
      // console.log("here");
      // offers.forEach(offer => {
      //     offer.QuoteIDs = offer.quotes.map(x => x.QuoteID)
      // });

      this.offers = offers;
    },

    sortChangeHandler(event) {
      this.sort = event.sort;
    },

    filterChangeHandler(event) {
      this.filter = event.filter;
    },

    pageChangeHandler(event) {
      this.take = event.page.take;
      this.skip = event.page.skip;
    },

    switchToCreatePolicyForm(event) {
      const quote = event.item;
      const offer = this.offers.find((x) =>
        x.quotes.map((x) => x.QuoteID).includes(quote.QuoteID)
      );

      const ageBandsInfo = offer.ageBandsSum.split(",").map(function (item) {
        const matches = item.match(/(\d+) x (\d+)-(\d+)/);

        return {
          ageBand: {
            minAge: Number(matches[2]),
            maxAge: Number(matches[3]),
          },
          persons: Number(matches[1]),
        };
      });

      const policyDetails = {
        quoteId: quote.QuoteID,
        packageName: quote.policyTypeName,
        price: quote.GrossPriceNum.toFixed(2),
        residence: offer.residence,
        destination: offer.destination,
        fromDate: offer.startDate,
        toDate: offer.endDate,
        personsPerAgeBand: ageBandsInfo,
      };

      // console.log(policyDetails);

      this.setCurrentQuoteForPolicyCreation(policyDetails);

      this.$router.replace({
        name: "newtravelpolicyrequestview",
      });
    },
  },
  inject: ["$appConfig"],
};
</script>
