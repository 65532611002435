<template>
  <!----------------------//v1.0.1.4  Εμφάνιση στο ίδιο σημείο με το NewQuoteWizard.vue-------------------------->
  <div class="flex flex-col">
    <div class="flex flex-row p-4">
      <div class="flex-col w-3/4">
        <!----------------------//v1.0.1.4  Εμφάνιση στο ίδιο σημείο με το NewQuoteWizard.vue  -------------------------->

        <!----------------------// //v1.0.2.3  Μορφοποίηση και στοίχιση κειμένου στα screening questions  -------------------------->
        <div
          class="bg-gray-100 mx-2 my-8 shadow-md rounded-lg"
          v-bind:class="extraClass"
        >
          <div class="px-2 py-2 divide-y divide-y-2 divide-[#d1182b]">
            <p class="px-2 mb-1 text-gray-900 font-semibold text-left">
              Screening questions
            </p>
            <slot></slot>
            <!-- <div class="flex flex-col w-full max-h-screen overflow-y-scroll mt-8"> -->
            <!-- <span class="font-semibold text-2xl">Screening questions</span> -->
            <!-- v-for="question in questions" -->
            <!-- v-bind:key="question" -->

            <div v-if="showQuestions" class="">
              <table class="table-fixed">
                <tbody>
                  <tr
                    class="border-b"
                  >
                    <td
                      v-html="questionText"
                      class="w-3/4 py-4 text-left px-8"
                    ></td>
                    <td>
                      <div class="p-4">
                        <!----------------------// //v1.0.2.3  Μορφοποίηση και στοίχιση κειμένου στα screening questions  -------------------------->

                        <!-- <k-checkbox :default-checked="false" v-model="question.accepted" label="I Agree" @change="checkFormValidation" /> -->
                        <!-- v-if="question.questionNumber == 3" -->
                        <k-radiogroup
                          v-model="questionsAccept"
                          :data-items="radioGroupItems"
                          :layout="'horizontal'"
                          @change="checkFormValidation"
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-else class="mt-10">
              <div v-if="gettingQuestions">
                <!----------------------// //v1.0.2.3  Μορφοποίηση και στοίχιση κειμένου στα screening questions  -------------------------->
                <span class="text-xl ml-72 mr-64"
                  >Παρακαλώ περιμένετε, γίνεται επικοινωνία με την ασφαλιστική
                  εταιρία.</span
                >
                <!----------------------// //v1.0.2.3  Μορφοποίηση και στοίχιση κειμένου στα screening questions  -------------------------->

                <div>
                  <svg
                    role="status"
                    class="inline w-10 h-10 mt-8 text-gray-200 animate-spin dark:text-gray-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="#1C64F2"
                    />
                  </svg>
                </div>
              </div>

              <div v-else class="mt-10">
                <!----------------------// //v1.0.2.3  Μορφοποίηση και στοίχιση κειμένου στα screening questions  -------------------------->
                <span class="text-xl ml-72 mr-64">
                  Δεν ήταν δυνατή η επικοινωνία με την ασφαλιστική εταιρία.
                  Παρακαλώ δοκιμάστε αργότερα.
                </span>
                <!----------------------// //v1.0.2.3  Μορφοποίηση και στοίχιση κειμένου στα screening questions  -------------------------->
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- //1.0.5.1	NewPolicyRequestStepOne.vue:	Προσθήκη σύνοψης (custom-card) στα screening questions  -->
      <custom-card title="Σύνοψη" :extraClass="'w-1/4'">
        <div class="pt-4">
          <table>
            <body>
              <!-- <tr v-for="(question, index) in questions" v-bind:key="question" class="tlabel">
                <div class="my-2">
                  <td v-if="question.accepted === false">
                    {{ `Ερώτηση ${index+=1}: No` }}
                  </td>
                  <td v-else-if="question.accepted === true">
                    {{ `Ερώτηση ${index+=1}: Yes` }}
                  </td>
                </div>
              </tr>
              <hr class="my-4" /> -->
              <tr>
                <td class="tlabel px-2 pt-2">Εταιρία</td>
                <td class="tvalue px-2 pt-2">Collinson</td>
              </tr>
              <tr>
                <td class="tlabel px-2 pt-2">Προσφορά</td>
                <td class="tvalue px-2 pt-2">
                  {{ this.$props.policyDetailsProp.quoteId }}
                </td>
              </tr>
              <tr>
                <td class="tlabel px-2 pt-2">Πακέτο</td>
                <td class="tvalue px-2 pt-2">
                  {{ this.$props.policyDetailsProp.packageName }}
                </td>
              </tr>
              <tr>
                <td class="tlabel px-2 pt-2">Σύνολο</td>
                <td class="tvalue px-2 pt-2">
                  {{ this.$props.policyDetailsProp.price }}
                </td>
              </tr>
              <tr>
                <td class="tlabel px-2 pt-2">Αναχώριση</td>
                <td class="tvalue px-2 pt-2">
                  {{ this.$props.policyDetailsProp.fromDate }}
                </td>
              </tr>
              <tr>
                <td class="tlabel px-2 pt-2">Επιστροφή</td>
                <td class="tvalue px-2 pt-2">
                  {{ this.$props.policyDetailsProp.toDate }}
                </td>
              </tr>
              <tr>
                <td class="tlabel px-2 pt-2">Κατοικία</td>
                <td class="tvalue px-2 pt-2">
                  {{ this.$props.policyDetailsProp.residence }}
                </td>
              </tr>
              <tr>
                <td class="tlabel px-2 pt-2">Προορισμός</td>
                <td class="tvalue w-80 pt-2">
                  {{ this.$props.policyDetailsProp.destination }}
                </td>
              </tr>
            </body>
          </table>
        </div>
      </custom-card>
      <!-- //1.0.5.1	NewPolicyRequestStepOne.vue:	Προσθήκη σύνοψης (custom-card) στα screening questions  -->
    </div>
  </div>
</template>

<style scoped>
.tlabel {
  text-align: left;
  vertical-align: middle;
  font-weight: bolder;
}
.tvalue {
  text-align: right;
  vertical-align: middle;
}
/* table, th, td {
  border: 1px solid black;
} */
</style>

<script>
import apiClient from "@/apiClient";
import { Checkbox } from "@progress/kendo-vue-inputs";
import { RadioGroup } from "@progress/kendo-vue-inputs";
import CustomCard from "@/components/CustomCard.vue";

export default {
  name: "StepOne",

  props: ["policyDetailsProp"],

  emits: ["onChange"],

  inject: ["kendoForm"],

  components: {
    "k-checkbox": Checkbox,
    "k-radiogroup": RadioGroup,
    "custom-card": CustomCard,
  },

  data() {
    return {
      questionsAccept: 0,
      gettingQuestions: false,
      questionText:"",

      radioGroupItems: [
        {
          label: "Yes",
          value: true,
        },
        {
          label: "No",
          value: false,
        },
      ],
      rawHtml: `<br>`,
    };
  },

  async mounted() {
    const token = await apiClient.login(null, null);

    this.kendoForm.values.apiToken = token;

    this.gettingQuestions = true;
    await this.getQuestions();

    this.gettingQuestions = false;

    // console.log(`got questions: ${this.questions.length}`)
    this.checkFormValidation();
    // console.log('step one mounted with token ' + this.token + ' and quote id ' + this.quoteId);
  },

  computed: {
    showQuestions() {
      return !this.gettingQuestions; //&& this.questions.length > 0
    },
  },

  methods: {
    async getQuestions() {
      const token = this.kendoForm.values.apiToken;
      const quoteId = this.$props.policyDetailsProp.quoteId;

      const questions = await apiClient.getScreeningQuestions(
        token,
        quoteId,
        "ASUA"
      );

      this.questionText=questions.Question;
      
      // console.log(questions)
      if (questions != null){
        this.questionsAccept = 0;
      }else{
        // console.log(false);
      }
        
    },

    checkFormValidation() {
      if (this.questionsAccept==0)
        //|| this.questions.length == 0)
        this.$emit("onChange", { stepValid: false });
      else this.$emit("onChange", { stepValid: true });
    },
  },
};
</script>
